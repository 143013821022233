import {Link} from "react-router-dom";

function Navbar() {
    return (
        <div>
            <div
                className="hidden md:block font-essentialSansMedium font-light text-gray bg-white border-gray-200 px-2 md:px-4 md:py-2.5 rounded w-full">
                <div className={"hidden md:block"}>
                    <div className={'flex justify-center w-full'}>
                        <div className="flex justify-center md:w-1/2 w-full">
                            <div className='container text-[1rem] flex flex-wrap justify-between items-center'
                                 style={{letterSpacing: "0.1rem"}}>
                                <div className={'flex justify-end w-1/5'}>
                                    <Link to="hpp">HPP</Link>
                                </div>
                                <div className={'flex justify-center w-1/5'}>
                                    <Link to="investors"> INVESTORS</Link>
                                </div>
                                <div className={'flex justify-center w-1/5 w-14'}>
                                    <Link to="/">
                                        <img
                                            src={'https://res.cloudinary.com/dfdvvquoy/image/upload/v1667937142/hpp/Logo_vzhubt.png'}
                                            alt="asd"/>
                                    </Link>
                                </div>
                                <div className={'flex justify-center w-1/5'}>
                                    <Link to="innovators">INNOVATORS </Link>
                                </div>
                                <div className={'flex justify-start w-1/5'}>
                                    <Link to='hydrogen'> HYDROGEN</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"w-full md:hidden"}>
                <div className={"flex text-sm justify-evenly items-center py-2"}>
                    <div className={""}>
                        <Link to="/">
                            <img
                                className={"h-10"}
                                src={'https://firebasestorage.googleapis.com/v0/b/hpp-website-38c1c.appspot.com/o/logo.svg?alt=media'}
                                alt="asd"/>
                        </Link>
                    </div>
                    <div>
                        <Link to="hpp">HPP</Link>
                    </div>
                    <div>
                        <Link to="investors"> INVESTORS</Link>
                    </div>

                    <div className={"flex justify-end"}>
                        <Link to="innovators">INNOVATORS </Link>
                    </div>
                    <div className={"flex justify-end"}>
                        <Link to='hydrogen'> HYDROGEN</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar
