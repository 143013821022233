import { investors_main, investors_secondary} from "../images_cdn";

export default function InvestorsScreen() {
    return (
        <div>
            <div>
                <div className=' relative'>
                    <img
                        src={investors_main}
                        alt=""
                        className={"w-full"}
                    />
                    <div className="absolute top-1/3 w-full justify-center">
                        <div className='text-center lg:mt-20 font-essentialSansBold text-white text-3xl lg:text-7xl'>Investors</div>
                    </div>
                </div>
                <div
                    className='flex text-white items-center justify-center p-8 lg:py-32  bg-deep font-essentialSans lg:text-2xl'>
                    <div className='lg:w-[70%] leading-7 lg:leading-[2.75rem]'>
                        <p className='text-left'>
                            Clean hydrogen will play a crucial role in the energy transition away from fossil fuels
                            over the next 30 years and beyond. Using renewable energy and other zero-emission power
                            sources to
                            generate clean hydrogen will have positive impacts on climate change and air quality on a
                            global level.
                        </p>
                        <p className={'mt-5 lg:mt-20 '}>
                            By 2050, hydrogen is projected to:
                        </p>
                        <div className={'mt-5  '}>
                            <div> - Reduce global CO2 emissions by 6 Gt (~20% of current energy-related emissions)</div>
                            <div> - Create 30 million new jobs</div>
                            <div> - Generate $2.5 trillion of direct revenues</div>
                            <div> - Develop $11 trillion of indirect infrastructure</div>
                        </div>
                    </div>
                </div>
                <div className="relative bg-deep bg-opacity-50"
                     style={{backgroundImage: `url(${investors_secondary})`, backgroundSize: "cover"}}>
                        <div className='text-white bg-deep bg-opacity-10 p-5 lg:py-36 lg:px-52 lg:text-2xl leading-7 lg:leading-[2.25rem]'>
                            <div>The investment opportunities being created have enormous economic and environmental
                                potential. Goldman Sachs, Bank of America, and Morgan Stanley have estimated the global
                                hydrogen economy could be on the order of $11 trillion by 2050. There is little doubt
                                hydrogen will
                                be essential and ubiquitous through the energy transition that has begun and expected to
                                accelerate
                                over the next several decades.
                            </div>
                            <div className={'mt-8 mg:mt-20'}>Private industry and governments are backing hydrogen as a key
                                energy vector that will
                                enable
                                the transition. Hydrogen strategies, policies and production targets have now been set
                                by more
                                than a dozen countries, with commensurate commitments for public investment monies. In
                                the
                                United States, there is a growing recognition of the importance of hydrogen. The
                                recently passed
                                Infrastructure Bill and the Inflation Reduction Act include specific allocations for
                                hydrogen RD&D
                                and scale up. The U.S. Department of Energy recently set an “Earthshot Goal” to reduce
                                the cost
                                of producing green hydrogen by 80% over the next decade.
                            </div>
                            <div className={'mt-8 mg:mt-20'}>
                                Market timing for investors and hydrogen businesses is at a stage where investment is
                                likely to generate
                                anomalously large potential returns, while also helping to save our planet for future
                                generations.
                            </div>
                            <div className={'mt-8 mg:mt-20'}>
                                In the emerging hydrogen economy, seed and early-stage companies offer the best
                                risk/reward for
                                both financial return and environmental impact. This is where the most innovative and
                                ambitious
                                technologists and entrepreneurs reside. Breakthrough technologies and businesses
                                developed here
                                will quickly become acquisition candidates for the major industrials, or fast-growth
                                companies.
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
