export default function TOU() {
    return (
        <div className={"px-6 text-justify lg:text-left lg:px-40 flex-col space-y-5 text-gray2 font-essentialSans"}>
            <p className={"font-essentialSansMedium mb-18 text-3xl"}>Our Terms of Use</p>

            <p className={"mb-10"}>Last updated October 9, 2022</p>

            <p className={"font-essentialSansMedium"}> AGREEMENT TO TERMS</p>

            <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on
                behalf of an entity (&ldquo;you&rdquo;) and HPP, LLC
                (&ldquo;we,&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;), concerning your access to and use of the
                Hydrogen Power Partners website as well as any other media form, media channel, mobile website or mobile
                application related, linked, or otherwise connected thereto (collectively, the &ldquo;Site&rdquo;).</p>

            <p>You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these
                Terms of Use. If you do not agree with all of these Terms of Use, then you are expressly prohibited from
                using the Site and you must discontinue use immediately.</p>

            <p>Supplemental Terms of Use or documents that may be posted on the Site from time to time are hereby
                expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make
                changes or modifications to these Terms of Use at any time and for any reason.</p>

            <p>We will alert you about any changes by updating the &ldquo;Last updated&rdquo; date of these Terms of
                Use, and you waive any right to receive specific notice of each such change.</p>

            <p>It is your responsibility to periodically review these Terms of Use to stay informed of updates. You will
                be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any
                revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are
                posted.</p>

            <p>The information provided on the Site is not intended for distribution to or use by any person or entity
                in any jurisdiction or country where such distribution or use would be contrary to law or regulation or
                which would subject us to any registration requirement within such jurisdiction or country.</p>

            <p>Accordingly, those persons who choose to access the Site from other locations do so on their own
                initiative and are solely responsible for compliance with local laws, if and to the extent local laws
                are applicable.</p>

            <p className={"font-essentialSansMedium"}> INTELLECTUAL PROPERTY RIGHTS</p>

            <p>Unless otherwise indicated, the Site is our proprietary property and all source code, databases,
                functionality, software, website designs, audio, video, text, photographs, and graphics on the Site
                (collectively, the &ldquo;Content&rdquo;) and the trademarks, service marks, and logos contained therein
                (the &ldquo;Marks&rdquo;) are owned or controlled by us or licensed to us, and are protected by
                copyright and trademark laws and various other intellectual property rights and unfair competition laws
                of the United States, foreign jurisdictions, and international conventions.</p>

            <p>The Content and the Marks are provided on the Site &ldquo;AS IS&rdquo; for your information and personal
                use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or
                Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded,
                translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose
                whatsoever, without our express prior written permission.</p>

            <p>Provided that you are eligible to use the Site, you are granted a limited license to access and use the
                Site and to download or print a copy of any portion of the Content to which you have properly gained
                access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you
                in and to the Site, the Content and the Marks.</p>

            <p className={"font-essentialSansMedium"}> PROHIBITED ACTIVITIES</p>

            <p>You may not access or use the Site for any purpose other than that for which we make the Site available.
                The Site may not be used in connection with any commercial endeavors except those that are specifically
                endorsed or approved by us.</p>

            <p>As a user of the Site, you agree not to:</p>

            <p> 1. systematically retrieve data or other content from the Site to create or compile, directly or
                indirectly, a collection, compilation, database, or directory without written permission from us.
                <br/>2.
                make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by
                electronic or other means for the purpose of sending unsolicited email, or creating user accounts by
                automated means or under false pretenses. <br/>3. use the Site to advertise or offer to sell goods and
                services. <br/>4. circumvent, disable, or otherwise interfere with security-related features of the Site,
                including features that prevent or restrict the use or copying of any Content or enforce limitations on
                the use of the Site and/or the Content contained therein. <br/>5. engage in unauthorized framing of or
                linking to the Site. <br/>6. trick, defraud, or mislead us and other users, especially in any attempt to
                learn sensitive account information such as user passwords; <br/>7. make improper use of our support services
                or submit false reports of abuse or misconduct. <br/>8. engage in any automated use of the system, such as
                using scripts to send comments or messages, or using any data mining, robots, or similar data gathering
                and extraction tools. <br/>9. interfere with, disrupt, or create an undue burden on the Site or the networks
                or services connected to the Site. <br/>10. use any information obtained from the Site in order to harass,
                abuse, or harm another person. <br/>11. decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of the Site. <br/>12. attempt to bypass any measures of
                the Site designed to prevent or restrict access to the Site, or any portion of the Site. <br/>13. harass,
                annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the
                Site to you. <br/>14. delete the copyright or other proprietary rights notice from any Content. <br/>15. copy or
                adapt the Site&rsquo;s software, including but not limited to Flash, PHP, HTML, JavaScript, or other
                code. <br/>16. upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other
                material, including excessive use of capital letters and spamming (continuous posting of repetitive
                text), that interferes with any party&rsquo;s uninterrupted use and enjoyment of the Site or modifies,
                impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of
                the Site. <br/>17. upload or transmit (or attempt to upload or to transmit) any material that acts as a
                passive or active information collection or transmission mechanism, including without limitation, clear
                graphics interchange formats (&ldquo;gifs&rdquo;), 1&times;1 pixels, web bugs, cookies, or other similar
                devices (sometimes referred to as &ldquo;spyware&rdquo; or &ldquo;passive collection
                mechanisms&rdquo; or &ldquo;pcms&rdquo;). except as may be the result of standard search engine or
                Internet browser usage, use, launch, develop, or distribute any automated system, including without
                limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or
                using or launching any unauthorized script or other software. <br/>18. disparage, tarnish, or otherwise harm,
                in our opinion, us and/or the Site. <br/>19. use the Site in a manner inconsistent with any applicable laws
                or regulations.</p>

            <p className={"font-essentialSansMedium"}>MOBILE APPLICATION LICENSE</p>

            <p>Use License</p>

            <p>If you access the Site via a mobile application, then we grant you a revocable, non-exclusive,
                non-transferable, limited right to install and use the mobile application on wireless electronic devices
                owned or controlled by you, and to access and use the mobile application on such devices strictly in
                accordance with the Terms of Use of this mobile application license contained in these Terms of Use.</p>

            <p>You shall not:</p>

            <p>(1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the
                application;</p>

            <p>(2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the
                application;</p>

            <p>(3) violate any applicable laws, rules, or regulations in connection with your access or use of the
                application;</p>

            <p>(4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark)
                posted by us or the licensors of the application;</p>

            <p>(5) use the application for any revenue generating endeavor, commercial enterprise, or other purpose for
                which it is not designed or intended;</p>

            <p>(6) make the application available over a network or other environment permitting access or use by
                multiple devices or users at the same time;</p>

            <p>(7) use the application for creating a product, service, or software that is, directly or indirectly,
                competitive with or in any way a substitute for the application;</p>

            <p>(8) use the application to send automated queries to any website or to send any unsolicited commercial
                e-mail;</p>

            <p>(9) use any proprietary information or any of our interfaces or our other intellectual property in the
                design, development, manufacture, licensing, or distribution of any applications, accessories, or
                devices for use with the application.</p>

            <p>Apple and Android Devices</p>

            <p>The following terms apply when you use a mobile application obtained from either the Apple Store or
                Google Play (each an &ldquo;App Distributor&rdquo;) to access the Site:</p>

            <p>(1) the license granted to you for our mobile application is limited to a non-transferable license to use
                the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and
                in accordance with the usage rules set forth in the applicable App Distributor&rsquo;s terms of use;</p>

            <p>(2) we are responsible for providing any maintenance and support services with respect to the mobile
                application as specified in the Terms of Use of this mobile application license contained in these Terms
                of Use or as otherwise required under applicable law, and you acknowledge that each App Distributor has
                no obligation whatsoever to furnish any maintenance and support services with respect to the mobile
                application;</p>

            <p>(3) in the event of any failure of the mobile application to conform to any applicable warranty, you may
                notify the applicable App Distributor, and the App Distributor, in accordance with its terms and
                policies, may refund the purchase price, if any, paid for the mobile application, and to the maximum
                extent permitted by applicable law, the App Distributor will have no other warranty obligation
                whatsoever with respect to the mobile application;</p>

            <p>(4) you represent and warrant that (i) you are not located in a country that is subject to a U.S.
                government embargo, or that has been designated by the U.S. government as a &ldquo;terrorist
                supporting&rdquo; country and (ii) you are not listed on any U.S. government list of prohibited or
                restricted parties;</p>

            <p>(5) you must comply with applicable third-party terms of agreement when using the mobile application,
                e.g., if you have a VoIP application, then you must not be in violation of their wireless data service
                agreement when using the mobile application;</p>

            <p>6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the Terms of Use
                in this mobile application license contained in these Terms of Use, and that each App Distributor will
                have the right (and will be deemed to have accepted the right) to enforce the Terms of Use in this
                mobile application license contained in these Terms of Use against you as a third-party beneficiary
                thereof.</p>

            <p className={"font-essentialSansMedium"}> SUBMISSIONS</p>

            <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other
                information regarding the Site (&ldquo;Submissions&rdquo;) provided by you to us are non-confidential
                and shall become our sole property. We shall own exclusive rights, including all intellectual property
                rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any
                lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>

            <p>You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such
                Submissions are original with you or that you have the right to submit such Submissions. You agree there
                shall be no recourse against us for any alleged or actual infringement or misappropriation of any
                proprietary right in your Submissions.</p>

            <p className={"font-essentialSansMedium"}> SITE MANAGEMENT</p>

            <p>We reserve the right, but not the obligation, to:</p>

            <p>(1) monitor the Site for violations of these Terms of Use;</p>

            <p>(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these
                Terms of Use, including without limitation, reporting such user to law enforcement authorities;</p>

            <p>(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of,
                or disable (to the extent technologically feasible) any of your Contributions or any portion
                thereof;</p>

            <p>(4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or
                otherwise disable all files and content that are excessive in size or are in any way burdensome to our
                systems;</p>

            <p>(5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate
                the proper functioning of the Site.</p>

            <p className={"font-essentialSansMedium"}> PRIVACY POLICY</p>

            <p>We care about data privacy and security. Please review our Privacy Policy [CLICK HERE]/posted on the
                Site]. By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these
                Terms of Use. Please be advised the Site is hosted in the United States.</p>

            <p>If you access the Site from the European Union, Asia, or any other region of the world with laws or other
                requirements governing personal data collection, use, or disclosure that differ from applicable laws in
                the United States, then through your continued use of the Site, you are transferring your data to the
                United States, and you expressly consent to have your data transferred to and processed in the United
                States.</p>

            <p>[Further, we do not knowingly accept, request, or solicit information from children or knowingly market
                to children. Therefore, in accordance with the U.S. Children&rsquo;s Online Privacy Protection Act, if
                we receive actual knowledge that anyone under the age of 13 has provided personal information to us
                without the requisite and verifiable parental consent, we will delete that information from the Site as
                quickly as is reasonably practical.]</p>

            <p> DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</p>

            <p className={"font-essentialSansMedium"}>Notifications</p>

            <p>We respect the intellectual property rights of others. If you believe that any material available on or
                through the Site infringes upon any copyright you own or control, please immediately notify our
                Designated Copyright Agent using the contact information provided below
                (a &ldquo;Notification&rdquo;).</p>

            <p>A copy of your Notification will be sent to the person who posted or stored the material addressed in the
                Notification. Please be advised that pursuant to federal law you may be held liable for damages if you
                make material misrepresentations in a Notification. Thus, if you are not sure that material located on
                or linked to by the Site infringes your copyright, you should consider first contacting an attorney.</p>

            <p>All Notifications should meet the requirements of DMCA 17 U.S.C. &sect; 512(c)(3) and include the
                following information:</p>

            <p>(1) A physical or electronic signature of a person authorized to act on behalf of the owner of an
                exclusive right that is allegedly infringed;</p>

            <p>(2) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted
                works on the Site are covered by the Notification, a representative list of such works on the Site;</p>

            <p>(3) identification of the material that is claimed to be infringing or to be the subject of infringing
                activity and that is to be removed or access to which is to be disabled, and information reasonably
                sufficient to permit us to locate the material;</p>

            <p>(4) information reasonably sufficient to permit us to contact the complaining party, such as an address,
                telephone number, and, if available, an email address at which the complaining party may be
                contacted;</p>

            <p>(5) a statement that the complaining party has a good faith belief that use of the material in the manner
                complained of is not authorized by the copyright owner, its agent, or the law;</p>

            <p>(6) a statement that the information in the notification is accurate, and under penalty of perjury, that
                the complaining party is authorized to act on behalf of the owner of an exclusive right that is
                allegedly infringed upon.</p>

            <p>Counter Notification</p>

            <p>If you believe your own copyrighted material has been removed from the Site as a result of a mistake or
                misidentification, you may submit a written counter notification to [us/our Designated Copyright Agent]
                using the contact information provided below (a &ldquo;Counter Notification&rdquo;).</p>

            <p>To be an effective Counter Notification under the DMCA, your Counter Notification must include
                substantially the following:</p>

            <p>(1) identification of the material that has been removed or disabled and the location at which the
                material appeared before it was removed or disabled;</p>

            <p>(2) a statement that you consent to the jurisdiction of the Federal District Court in which your address
                is located, or if your address is outside the United States, for any judicial district in which we are
                located;</p>

            <p>(3) a statement that you will accept service of process from the party that filed the Notification or the
                party&rsquo;s agent;</p>

            <p>(4) your name, address, and telephone number;</p>

            <p>(5) a statement under penalty of perjury that you have a good faith belief that the material in question
                was removed or disabled as a result of a mistake or misidentification of the material to be removed or
                disabled;</p>

            <p>(6) your physical or electronic signature.</p>

            <p>If you send us a valid, written Counter Notification meeting the requirements described above, we will
                restore your removed or disabled material, unless we first receive notice from the party filing the
                Notification informing us that such party has filed a court action to restrain you from engaging in
                infringing activity related to the material in question.</p>

            <p>Please note that if you materially misrepresent that the disabled or removed content was removed by
                mistake or misidentification, you may be liable for damages, including costs and attorney&rsquo;s fees.
                Filing a false Counter Notification constitutes perjury.</p>

            <p>Designated Copyright Agent</p>

            <p>Stephen MacIntosh</p>

            <p>Attn: Copyright Agent</p>

            <p>319 East Carrillo Street</p>

            <p>Santa Barbara, CA 93101</p>

            <p>stephen.macintosh@hydrogenpowerpartners.com</p>

            <p className={"font-essentialSansMedium"}> COPYRIGHT INFRINGEMENTS</p>

            <p>We respect the intellectual property rights of others. If you believe that any material available on or
                through the Site infringes upon any copyright you own or control, please immediately notify us using the
                contact information provided below (a &ldquo;Notification&rdquo;). A copy of your Notification will be
                sent to the person who posted or stored the material addressed in the Notification.</p>

            <p>Please be advised that pursuant to federal law you may be held liable for damages if you make material
                misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by
                the Site infringes your copyright, you should consider first contacting an attorney.]</p>

            <p className={"font-essentialSansMedium"}>TERM AND TERMINATION</p>

            <p>These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY
                OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
                NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO
                ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
                REGULATION.</p>

            <p className={"font-essentialSansMedium"}> MODIFICATIONS AND INTERRUPTIONS</p>

            <p>We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason
                at our sole discretion without notice. However, we have no obligation to update any information on our
                Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any
                time.</p>

            <p>We will not be liable to you or any third party for any modification, price change, suspension, or
                discontinuance of the Site.</p>

            <p>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or
                other problems or need to perform maintenance related to the Site, resulting in interruptions, delays,
                or errors.</p>

            <p>We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any
                time or for any reason without notice to you. You agree that we have no liability whatsoever for any
                loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or
                discontinuance of the Site.</p>

            <p>Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to
                supply any corrections, updates, or releases in connection therewith.</p>

            <p className={"font-essentialSansMedium"}> GOVERNING LAW</p>

            <p>These Terms of Use and your use of the Site are governed by and construed in accordance with the laws of
                the State of California applicable to agreements made and to be entirely performed within the
                State/Commonwealth of California, without regard to its conflict of law principles.</p>

            <p className={"font-essentialSansMedium"}>CORRECTIONS</p>

            <p>There may be information on the Site that contains typographical errors, inaccuracies, or omissions that
                may relate to the Site, including descriptions, pricing, availability, and various other information. We
                reserve the right to correct any errors, inaccuracies, or omissions and to change or update the
                information on the Site at any time, without prior notice.</p>

            <p className={"font-essentialSansMedium"}> DISCLAIMER</p>

            <p>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR
                SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE
                NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE&rsquo;S CONTENT OR THE
                CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1)
                ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
                ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO
                OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
                THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES,
                TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR
                (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
                AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE
                DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
                FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>

            <p>AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
                YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>

            <p className={"font-essentialSansMedium"}> LIMITATIONS OF LIABILITY</p>

            <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
                PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
                BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

            <p className={"font-essentialSansMedium"}> INDEMNIFICATION</p>

            <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of
                our respective officers, agents, partners, and employees, from and against any loss, damage, liability,
                claim, or demand, including reasonable attorneys&rsquo; fees and expenses, made by any third party due
                to or arising out of: (1) [your Contributions]; (2) use of the Site; (3) breach of these Terms of Use;
                (4) any breach of your representations and warranties set forth in these Terms of Use; (5) your
                violation of the rights of a third party, including but not limited to intellectual property rights; or
                (6) any overt harmful act toward any other user of the Site with whom you connected via the Site.</p>

            <p>Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and
                control of any matter for which you are required to indemnify us, and you agree to cooperate, at your
                expense, with our defense of such claims. We will use reasonable efforts to notify you of any such
                claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>

            <p className={"font-essentialSansMedium"}>USER DATA</p>

            <p>We will maintain certain data that you transmit to the Site for the purpose of managing the Site, as well
                as data relating to your use of the Site. Although we perform regular routine backups of data, you are
                solely responsible for all data that you transmit or that relates to any activity you have undertaken
                using the Site.</p>

            <p>You agree that we shall have no liability to you for any loss or corruption of any such data, and you
                hereby waive any right of action against us arising from any such loss or corruption of such data.</p>

            <p className={"font-essentialSansMedium"}> ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</p>

            <p>Visiting the Site, sending us emails, and completing online forms constitute electronic communications.
                You consent to receive electronic communications, and you agree that all agreements, notices,
                disclosures, and other communications we provide to you electronically, via email and on the Site,
                satisfy any legal requirement that such communication be in writing.</p>

            <p>YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO
                ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR
                VIA THE SITE.</p>

            <p>You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other
                laws in any jurisdiction which require an original signature or delivery or retention of non-electronic
                records, or to payments or the granting of credits by any means other than electronic means.</p>

            <p className={"font-essentialSansMedium"}> CALIFORNIA USERS AND RESIDENTS</p>

            <p>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of
                the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625
                North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916)
                445-1254.</p>

            <p className={"font-essentialSansMedium"}> MISCELLANEOUS</p>

            <p>These Terms of Use and any policies or operating rules posted by us on the Site constitute the entire
                agreement and understanding between you and us. Our failure to exercise or enforce any right or
                provision of these Terms of Use shall not operate as a waiver of such right or provision.</p>

            <p>These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our
                rights and obligations to others at any time. We shall not be responsible or liable for any loss,
                damage, delay, or failure to act caused by any cause beyond our reasonable control.</p>

            <p>If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or
                unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and
                does not affect the validity and enforceability of any remaining provisions.</p>

            <p>There is no joint venture, partnership, employment or agency relationship created between you and us as a
                result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed
                against us by virtue of having drafted them.</p>

            <p>You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and
                the lack of signing by the parties hereto to execute these Terms of Use.</p>

            <p className={"font-essentialSansMedium"}> CONTACT US</p>

            <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the
                Site, please contact us at:</p>

            <p>Hydrogen Power Partners, LP</p>

            <p>319 East Carrillo Street</p>

            <p>Santa Barbara, CA 93101</p>

            <p></p>
        </div>
    )
}
