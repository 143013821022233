import * as React from "react";
import {useEffect} from "react";
import {home_tl, home_tr, home_br, home_bl, home_video} from "../images_cdn";
import {Link} from "react-router-dom";

function HomeScreen() {
    const videoEl = React.useRef(null);

    const attemptPlay = () => {
        videoEl && videoEl.current && videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay()
    })
    return (<div>
        <div>
            <div className="relative w-full">
                <video
                    playsInline
                    loop
                    muted
                    alt=""
                    ref={videoEl}
                    className="w-full"
                >
                    <source src={home_video} type="video/mp4"/>
                </video>
                <div
                    className="absolute text-white font-sans text-2xl lg:text-8xl font-sans  top-[15%] md:top-[20%] w-full justify-center font-times">
                    <div className='text-center font-essentialSansLight'><span
                        className='font-essentialSansMedium'>Critical</span> Challenges.
                    </div>
                    <div className='text-center font-essentialSansLight '>Historic <span
                        className='font-essentialSansMedium'>Opportunities.</span></div>
                </div>
            </div>
        </div>
        <div className='flex  text-white items-center justify-center md:py-28  bg-deep '>
            <div className='md:w-[75%]'>
                <p className='text-justify md:text-center leading-6 md:leading-[2.4rem] text-base md:text-[1.563rem] text-lg font-light p-6 md:p-10 xl:p-30'
                   style={{wordSpacing: "0.2rem"}}>
                    Hydrogen Power Partners invests in early stage companies in the emerging “hydrogen economy.”
                    Our mission is to accelerate the development of innovative companies to help
                    mitigate climate change, improve health, and increase energy security.
                </p>
            </div>
        </div>
        <div className='md:text-[1.5rem] text-[1.1rem] font-bold text-white' style={{letterSpacing: "0.2rem"}}>
            <div className='md:flex md:flex-wrap '>
                <div className='md:w-1/2 relative'>
                    <Link to={"hpp"}>
                        <img
                            src={home_tl}
                            alt="" className='grayscale hover:grayscale-0'/>
                        <div className="absolute    top-[45%] md:top-[40%] w-full  justify-center">
                            <div className='text-center md:mt-20 font-essentialSansBold'>HYDROGEN POWER PARTNERS</div>
                        </div>
                    </Link>
                </div>
                <div className='md:w-1/2 relative'>
                    <Link to={"investors"}>
                        <img
                            src={home_tr}
                            alt="" className='grayscale hover:grayscale-0'/>
                        <div className="absolute   top-[45%] md:top-[40%] w-full  justify-center">
                            <div className='text-center md:mt-20 font-essentialSansBold'>INVESTORS</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='md:flex md:flex-wrap'>
                <div className='md:w-1/2 relative'>
                    <Link to={'innovators'}>
                        <img
                            src={home_bl}
                            alt="" className='grayscale hover:grayscale-0'/>
                        <div className="absolute top-[45%] md:top-[40%] w-full  justify-center">
                            <div className='text-center font-essentialSansBold'>INNOVATORS</div>
                        </div>
                    </Link>
                </div>
                <div className='md:w-1/2 relative'>
                    <Link to={"hydrogen"}>
                        <img
                            src={home_br}
                            alt="" className='grayscale hover:grayscale-0'
                        />
                        <div className="absolute  top-[45%] md:top-[40%] w-full  justify-center">
                            <div className='text-center font-essentialSansBold'>HYDROGEN</div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>)
}

export default HomeScreen
