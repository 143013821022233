const main = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938808/hydrogen/main_lofxm5.jpg";
const img_1 = "https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_1500/v1667942030/hydrogen/1193C2E1-B1F2-4697-8377-D60B2C393A3D_lhgyid.jpg"
const img_2 = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938813/hydrogen/2_elzekn.jpg"
const img_3 = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938803/hydrogen/3_xvcrzd.jpg"
const img_4 = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938799/hydrogen/4_hsofcf.jpg"
const img_5 = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938800/hydrogen/5_dphvuf.jpg"
const img_6 = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938818/hydrogen/6_gh9tsi.jpg"
const img_7 = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938822/hydrogen/7_obkj8s.jpg"
const img_8 = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938850/hydrogen/8_ixj147.jpg"
const img_9 = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938800/hydrogen/9_po8xls.jpg"
const img_10 = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938806/hydrogen/10_oit5od.jpg"

export default function HydrogenScreen() {
    return (
        <div>
            <div className=' relative'>
                <img
                    className={"w-full"}
                    src={main}
                    alt=""/>
                <div className="absolute top-1/3 w-full justify-center">
                    <div
                        className='text-center lg:mt-20 font-essentialSansBold text-white text-3xl lg:text-7xl'>Hydrogen
                    </div>
                </div>
            </div>
            <div className='flex text-white items-center justify-center lg:py-36 py-7 bg-deep font-essentialSans'>
                <div className='lg:w-[70%]'>
                    <p className='text-justify p-6 lg:p-0 lg:text-center lg:text-3xl text-lg'>
                        To appreciate the challenges and opportunities presented by the adoption of hydrogen as an
                        alternative to fossil fuels, and the “Hydrogen Economy” that must be built around it, it is
                        necessary to understand what it is, why it is gaining so much momentum, and how it must be
                        managed from hydrogen production to end use.
                    </p>
                </div>
            </div>
            <div className={"p-6 lg:p-20 flex-col space-y-10 lg:text-2xl  text-gray2 text-justify lg:text-left"}>
                <div className={"flex-col space-y-6 mt-5"}>
                    <div>
                        Hydrogen is the smallest of all atoms. It is the lightest and most abundant element in the
                        universe. As an energy carrier it holds great promise. Hydrogen per unit of weight has
                        nearly 3 times the energy content of gasoline. Yet, on a volume basis its heating value is
                        significantly less at about one-third that of natural gas. It can be made via electrolysis
                        by splitting water, through a chemical reforming process using hydrogen or other hydrogen
                        carriers, or by converting waste to hydrogen.
                    </div>
                    <div>
                        Electricity from renewable sources such as solar, wind, hydro, wave, or geothermal are
                        inherently intermittent but can be used to produce clean hydrogen which in turn can be
                        stored to meet energy demand when renewables cannot. Optimizing the production and use of
                        renewable energy, and other zero-carbon power generation sources will be a necessary
                        strategy in making clean hydrogen. Ultimately, hydrogen will serve as an important bridge
                        between the physical energy of renewables and the energy demand that would otherwise be met
                        by fossil fuels.
                    </div>
                    <div className={"w-full lg:p-10"}>
                        <img src={img_1} alt="" className={"w-full"}/>
                    </div>
                    <div>
                        Hydrogen can be converted to electricity via internal combustion (IC) in gas turbine
                        generators or electrochemically in fuel cells. A fuel cell generates electricity from the
                        introduction of hydrogen and atmospheric oxygen; water and heat are the only by-products. It
                        has no moving parts. Hydrogen and oxygen are passed through an electrolyte membrane
                        impregnated with a catalyst, usually platinum, to facilitate the reaction. Fuel cells can be
                        used to power a full range of transportation modes, for goods handling (forklifts), to power
                        buildings and data centers, and as a source of electricity for grid power.
                    </div>
                </div>
                <div className={"flex-col space-y-5"}>
                    <div className={"font-semibold"}>Production Methods</div>
                    <div> Hydrogen is currently differentiated by production method, commonly referred to as “Gray”,
                        “Blue”, “Green” or “Brown.” But ultimately, it is the carbon intensity of the production
                        pathway that is the primary issue. Primary hydrogen production methods are summarized below.
                    </div>
                    <div><span className={'font-medium'}>Steam Methane Reforming (SMR)</span>. This process uses high
                        temperature steam mixed with natural
                        gas to produce hydrogen, but with high CO2 emissions. This pathway represented 95% of the US
                        hydrogen production in 2020 (“Gray hydrogen”).
                    </div>
                    <div><span className={'font-medium'}>SMR with the capture and storage of CO2 emissions (Carbon Capture and Storage - CCS).</span> CO2
                        emissions are reduced by 70-90% using CCS, but currently, less than 1% of US hydrogen
                        production uses CCS technology (“Blue hydrogen").
                    </div>
                    <div><span className={'font-medium'}>Electrolysis.</span> Electricity is used to split water into
                        hydrogen and oxygen. All forms of
                        electricity can be used in this pathway, when the electricity used is renewable the hydrogen
                        can be zero-carbon. Only 0.3% of the 2020 hydrogen produced in the US was made this way.
                        Zero-carbon hydrogen is gaining interest with government and industry, with this production
                        method receiving the most investment currently. The main cost in this process is the cost of
                        power for production. The decreasing cost of renewable energy as well as improvements in
                        electrolyzer cost and efficiency are expected to make the cost for this production method on
                        par with SMR by 2030 (“Green hydrogen”).
                    </div>
                    <div><span className={'font-medium'}>Coal gasification using brown or black coal.</span> Several
                        industries (and countries) already
                        convert these carbon-rich materials into hydrogen, but with high emissions of CO2 (“Brown
                        hydrogen” and “Black hydrogen”, respectively for the two types of coal).
                    </div>
                    <div className={"w-full lg:p-10"}>
                        <img src={img_2} alt="" className={"w-full"}/>
                    </div>
                    <div>When used in transportation (and other end uses), hydrogen has an immediate benefit for air
                        quality thereby reducing adverse health impacts since there are no emissions at the point of
                        use. Of course, the same benefit is not immediately there for climate impacts, not until the
                        production methods shift to low carbon methods and life-cycle carbon intensities are less
                        than fossil alternatives. Currently, blue and green hydrogen are up to 80 and 600 percent
                        more expensive, respectively, than gray hydrogen.
                    </div>
                    <div>“Carbon intensity” as a metric to differentiate hydrogen production by total emissions of
                        CO2 is currently gaining acceptance to supplant the color naming convention. Factors such as
                        energy consumption, transportation, liquification, and feedstock source contribute to GHG
                        emissions along the entire hydrogen value chain. For transportation fuels, the California
                        Air Resources Board (CARB) uses a life-cycle methodology to account for these emissions from
                        source to use. CARB publishes default carbon intensity values for an extensive range of
                        production pathways.
                    </div>
                    <div>The current global market for hydrogen is on the order of 90 million metric tons, with over
                        90% of this used to produce ammonia and to desulfurize fuel in petroleum refining. Almost
                        all this hydrogen is produced using SMR which generates a high level of GHG emissions –
                        about 10 metric tons of CO2 for every metric ton of hydrogen produced, representing
                        approximately 2% of global CO2 emissions. This implies an immediate opportunity for
                        low-carbon hydrogen to mitigate emissions from current production methods. It is projected
                        that hydrogen demand could increase 10-fold by 2050.
                    </div>
                    <div className={"w-full lg:p-10 flex justify-center"}>
                        <img src={img_3} alt="" className={"lg:w-1/2"}/>
                    </div>
                </div>
                <div className={"flex-col space-y-5"}>
                    <div className={"font-semibold"}>Fuel Cell Technologies</div>
                    <div>Fuel cells use an electrochemical reaction to produce electricity from hydrogen and oxygen
                        with water and heat as by-products. The fuel cell is a device with an anode and cathode and
                        a membrane with a catalyst (typically platinum) between them. Fuel cells that use pure
                        hydrogen are completely carbon-free. Fuel cells can also be designed and manufactured to use
                        other gas feeds such as methanol, natural gas and biogas, which are not necessarily “low
                        carbon.”
                    </div>
                    <div className={"w-full lg:p-10 flex justify-center"}>
                        <img src={img_4} alt="" className={"lg:w-1/2"}/>
                    </div>
                    <div>Innovation in fuel cell design leading to higher efficiency and less costly materials is a
                        major area of investment opportunity. Various chemistries with differing operating
                        characteristics for heat, hydrogen feedstock purity, and suitability for the intended
                        application are already in use and seeking scale-up.
                    </div>
                    <div className={"w-full lg:p-10 flex justify-center"}>
                        <img src={img_5} alt="" className={""}/>
                    </div>
                </div>
                <div className={"flex-col space-y-5"}>
                    <div className={"font-semibold"}>The Hydrogen Economy</div>
                    <div>Frequently reference is made to the approaching “Hydrogen Economy.” This refers to the
                        technologies, infrastructure, businesses, government, and people needed to support hydrogen
                        from production, packaging, distribution, storage, and transfer through to end use.
                    </div>
                    <div className={"w-full lg:p-10 flex justify-center"}>
                        <img src={img_6} alt="" className={""}/>
                    </div>
                    <div>As shown in the figure below, hydrogen can decarbonize many industrial sectors, especially
                        those that are “hard to abate.”
                    </div>
                    <div className={"w-full lg:p-10 flex justify-center"}>
                        <img src={img_7} alt="" className={""}/>
                    </div>
                    <div>Large-scale deployment of hydrogen will require optimal efficiency and low cost throughout
                        the hydrogen value chain from production to point of use. Cost and efficiency are key to
                        making hydrogen a viable alternative to fossil fuels for end-use applications. This
                        represents many opportunities for investment with innovative companies working in all
                        segments of the hydrogen value chain.
                    </div>
                    <div>Hydrogen efficiency (or “round-trip efficiency”) is a measure of how much electricity can
                        be made from a given amount of hydrogen divided by how much electricity it takes to make
                        that amount of hydrogen (power out to power in). Hydrogen’s efficiency will vary by
                        production, storage, transport, and energy conversion method.
                    </div>
                    <div>The storage of hydrogen for later conversion to electricity is a means of offsetting the
                        need for fossil fuels when renewables are not producing power. Hydrogen can be an integral
                        part of the system to store that energy for multiple end use applications.
                    </div>
                </div>
                <div className={"flex-col space-y-5"}>
                    <div className={"font-semibold"}>Low-Carbon Hydrogen Production</div>
                    <div>Electrolyzers are both mature and developing technologies that produce hydrogen using only
                        electricity and water, with no carbon emissions. The most common mature electrolyzers are
                        Alkaline and Proton Exchange Membrane (PEM). Alkaline uses an alkaline catalyst and
                        electricity. PEM uses a hydrogen permeable membrane with platinum or iridium as a catalyst.
                        Iridium is currently not recyclable. Alkaline and PEM electrolyzers have similar
                        efficiencies (currently in the range of 50%-70%). PEM is suitable for intermittent energy
                        from renewables and has a wider range of operating conditions.
                    </div>
                    <div>Solid Oxide Fuel Cells have long been used for reliable, distributed energy production.
                        Also, the use of Solid Oxide (high temperature) technology is emerging as a promising new
                        way to produce hydrogen. Solid Oxide Electrolyzer Cells (SOEC) have higher capacity (in
                        general) than Alkaline or PEM, and when heat recovery is used, their efficiency can be close
                        to 100%. SOEC electrolyzers have no platinum, only nickel. Nickel is sufficiently abundant
                        to support the technology at scale.
                    </div>
                    <div>One other electrolyzer technology being developed is Electrochemical and Thermally
                        Activated Chemical (E-TAC), which is essentially in the research phase. E-TAC fuel cells
                        claim to have higher efficiencies using heat as well as electricity.
                    </div>
                    <div>Methane Pyrolysis and Gasification are other emerging production technologies with
                        potential to use a wide range of fossil and biogenic feedstocks that can produce hydrogen
                        and “capture” the carbon in the process that can produce hydrogen, with carbon black or
                        biochar as by-products (both of which could produce a co-benefit revenue stream).
                    </div>
                    <div>Key features of the electrolyzer technologies are summarized in the tables below.</div>
                    <div className={"w-full lg:p-10 flex justify-center"}>
                        <img src={img_8} alt="" className={""}/>
                    </div>
                </div>
                <div className={"flex-col space-y-5"}>
                    <div className={"font-semibold"}>Storage</div>
                    <div>Hydrogen is the lightest element on earth. Comparatively one gallon of gasoline is ~2.75KG
                        where a gallon of hydrogen is 0.00075 kg (@1atm/0°C). To store (and transport) large
                        amounts, hydrogen must be pressurized and delivered as a compressed gas or cooled to the
                        point of liquification (-253 degrees Celsius or -423 degrees Fahrenheit).
                    </div>
                    <div>Alternative storage techniques have been and continue to be developed. In order of
                        increasing density, hydrogen can be stored on a) the surfaces of solids by adsorption, b) by
                        absorption - dissociating into hydrogen atoms that incorporate into a solid lattice
                        framework, or c) bound to chemical compounds containing hydrogen atoms (chemical hydrides).
                        While these techniques make it possible to store more hydrogen in less space at low pressure
                        and temperature, they also make hydrogen easier to handle as solid or liquid structures.
                        Note, energy must be expended to separate the hydrogen from these carriers (while energy is
                        also required for their formation).
                    </div>
                </div>
                <div className={"flex-col space-y-5"}>
                    <div className={"font-semibold"}>Transport</div>
                    <div>There are ~1,600 miles of hydrogen pipeline in the US, and thousands more for natural gas.
                        Most of these hydrogen pipelines are located where large industrial operations are already
                        using hydrogen in significant quantities (refineries and chemical plants). Pipeline
                        transport of gaseous hydrogen is a low-cost option for delivering large volumes. However,
                        while hydrogen flows three times faster than natural gas, it is significantly less energy
                        dense. Furthermore, hydrogen is not compatible with high iron content steels and welds of
                        natural gas pipelines leading to embrittlement of pipelines over time. Plastic,
                        polyethylene, fiber reinforced plastic, and epoxy coated pipes are compatible and impervious
                        to hydrogen. Replacement and repair of older, leaking pipelines will reduce GHG emissions.
                        Blends of 20% hydrogen with natural gas are an interim solution while infrastructure
                        technologies are developed to safely transport low carbon hydrogen.
                    </div>
                    <div>Hydrogen as a compressed gas can be transported in high pressure gas cylinders on trucks
                        with hydrogen tube trailers. Liquified hydrogen in cryogenic tanks is also an option for
                        road transport, with even greater potential when transported by ship or as a fuel in the
                        maritime sector. Compression requires energy which impacts round-trip efficiency.
                        Alternatives to mechanical compression will be an area for meaningful innovation.
                    </div>
                    <div>Innovations in storing hydrogen in solid or liquid carriers will produce new options for
                        transport, more or less viable depending on end use. The figure below shows how hydrogen can
                        be delivered throughout the hydrogen value chain.
                    </div>
                    <div className={"w-full lg:p-10 flex justify-center"}>
                        <img src={img_9} alt="" className={""}/>
                    </div>
                </div>
                <div className={"flex-col space-y-5"}>
                    <div className={"font-semibold"}>Hydrogen End Uses</div>
                    <div>Hydrogen will play a significant role in achieving net-zero, or global decarbonization
                        goals. The extent to which it does this will depend on hydrogen technology developments, the
                        scale of deployment, and commercial competitiveness with viable alternatives.
                    </div>
                    <div>Hydrogen Fuel for Transport and Mobility - Fossil fuel use in cars, buses, trucks,
                        forklifts, trains, and other modes of transportation will likely be replaced by batteries or
                        hydrogen (in fuel cells to power electrical motors), with hydrogen playing an essential role
                        for heavy duty vehicles, trains, and marine vessels, as well as hard to abate industrial
                        sectors.
                    </div>
                    <div>Hydrogen, compressed or liquified, could also be used with combustion turbines and Internal
                        Combustion Engines (ICEs), but ultimately, it is likely that fuel cells will be the primary
                        end use application. Moving away from combustion and increasing the use of fuel cells is
                        desirable to eliminate the potential for criteria pollutant emissions (especially NOx) which
                        occurs with combustion.
                    </div>
                    <div>Fuel cells will play a critical role in reducing the environmental impact of vehicles on
                        the road. Cars and trucks are responsible for approximately 20% of all US carbon emissions,
                        releasing 24 lbs. of CO2 and other global warming gases per gallon of gasoline consumed.
                    </div>
                    <div>Feedstock – Of the 90 million metric tons of hydrogen produced annually today, over 95% of
                        it is used as feedstocks in oil refining (desulfurization), ammonia, methanol, and steel
                        production. Almost all this hydrogen is supplied by fossil fuels via SMR which has a 10:1
                        ratio of CO2 emissions to hydrogen generated. Other industries, such as plastics, iron,
                        steel, glass, metallurgy, and agriculture, could greatly benefit from the use of clean
                        hydrogen in their processes.
                    </div>
                    <div>Industrial Energy Use - Industrial sectors such as steel, aluminum, paper, and cement need
                        high intensity heat, which they currently get from fossil fuels. Combined, these industries
                        are responsible for >30% of global CO2 emissions. For these “hard-to-abate” sectors,
                        electricity is not a viable option, but hydrogen is a capable low-carbon, zero-CO2
                        alternative
                    </div>
                    <div>Building Heat and Power - Buildings are a very large user of fossil fuels, mainly natural
                        gas for heating. Hydrogen holds promise as an alternative low-carbon heating source whether
                        as a blend with natural gas or as pure hydrogen fuel. Fuel cells are capable powering homes
                        and businesses, including critical facilities like hospitals and data centers.
                    </div>
                    <div>Large Scale Integration of Renewables and Utility-Scale Power Generation - For utilities,
                        hydrogen for electricity demand has various applications at scale, either for massive,
                        long-duration energy storage, no self-discharge, and for grid balancing in tandem with
                        renewable energy because of its intermittent nature. At the same time, hydrogen can act as
                        an energy buffer to increase utility system resiliency.
                    </div>
                    <div>Cross-Sector Energy Distribution - Hydrogen has the capacity to distribute energy across
                        industrial sectors and geographic regions. This versatility is a key vector that hydrogen
                        alone can provide.
                    </div>
                    <div className={"w-full lg:p-10 flex justify-center"}>
                        <img src={img_10} alt="" className={""}/>
                    </div>
                </div>
            </div>

        </div>
    )
}
