export default function ContactScreen() {
    return (
        <div>
            <div
                className='lg:flex text-white items-center justify-center lg:py-36 p-3 py-16  bg-deep font-essentialSans lg:px-20'>
                <div className='lg:w-[40%] py-4 md:p-10  text-center lg:text-6xl text-3xl font-bold'>
                    Contact Us
                </div>
                <div className='lg:w-[60%] lg:text-2xl lg:text-xl lg:pl-10'>
                    <div className='font-essentialSansBold'>Please contact any of our partners directly.</div>
                    <div className='lg:flex mt-16'>
                        <div className='font-essentialSansBold lg:w-1/2'>Steven Worzman</div>
                        <div className='w-full '> steven.worzman@hydrogenpowerpartners.com</div>
                    </div>

                    <div className='lg:flex mt-8'>
                        <div className='font-essentialSansBold lg:w-1/2'>Ivor John</div>
                        <div className='w-full '> ivor.john@hydrogenpowerpartners.com</div>
                    </div>
                    <div className='lg:flex mt-8'>
                        <div className='font-essentialSansBold lg:w-1/2'>Stephen MacIntosh</div>
                        <div className='w-full '> stephen.macintosh@hydrogenpowerpartners.com</div>
                    </div>
                </div>
            </div>
            <div className={'flex-col space-y-4 text-2xl lg:text-4xl  font-bold text-deep py-24'}>
                <div className={'flex justify-center'}> Hydrogen Power Partners, LP</div>
                <div className={'flex justify-center'}> 319 East Carrillo Street</div>
                <div className={'flex justify-center'}> Santa Barbara, California 93101</div>
            </div>

        </div>
    )
}
