import {
    home_br,
    home_tl,
    innovators_company_stage, innovators_company_stage_m,
    innovators_cover,
    innovators_investment_focus, innovators_investment_focus_m,
    innovators_investment_size, innovators_investment_size_m
} from "../images_cdn";
import {useState} from "react";

export default function InnovatorsScreen() {
    const [heading, setHeading] = useState(0)

    const onClickHeading = (id) => {
        if (heading === id)
            setHeading(0)
        else
            setHeading(id)

    }

    return (
        <div>
            <div className=' relative'>
                <img
                    src={innovators_cover}
                    alt=""/>
                <div className="absolute top-1/3 w-full justify-center">
                    <div
                        className='text-center lg:mt-20 font-essentialSansBold text-white text-3xl lg:text-7xl'>Innovators
                    </div>
                </div>
            </div>
            <div className='flex text-white items-center justify-center py-10 md:py-36  bg-deep font-essentialSans'>
                <div className='lg:w-[70%]'>
                    <p className='text-justify px-6 lg:p-0 lg:text-center md:text-3xl text-lg'>
                        Are you and your team creating innovative solutions within the
                        hydrogen value chain? If you’re developing new technology that
                        will address the critical challenges in production, distribution, or
                        end uses, let’s explore how our team of industry leaders, scientists,
                        engineers, entrepreneurs and venture capitalistscan help accelerate
                        your growth.
                    </p>
                </div>
            </div>
            <div className='text-white text-6xl'>
                <div className='relative' onClick={() => onClickHeading("investment_focus")}>
                    <img
                        src={innovators_investment_focus}
                        alt="" className='w-full hidden md:block'
                    />
                    <img
                        src={innovators_investment_focus_m}
                        alt="" className='w-full md:hidden'
                    />
                    <div style={{height: "100%",}}
                         className={`absolute top-0  text-center w-full justify-center flex  bg-deep ${heading === "investment_focus" ? "opacity-70" : " opacity-30"}`
                         }/>
                    <div style={{height: "100%"}} className="absolute top-0  text-center w-full justify-center flex">
                        {heading === "investment_focus" ? (
                                <div style={{height: "100%"}}
                                     className='text-base leading-5 text-justify px-6 lg:p-0 lg:text-2xl lg:text-left lg:w-1/2 flex items-center justify-center font-essentialSansLight w-full'>
                                    <div>
                                        <div>
                                            HPP is focused on emerging technologies in all verticals of the emerging
                                            “Hydrogen Economy.” We invest in companies that are driving innovation
                                            in technologies that can reduce costs, improve performance, and improve
                                            durability related to:
                                        </div>
                                        <div className={"mt-4 lg:mt-10"}>
                                                <div className={"px-3 md:p-0"}>
                                                    <div> - Hydrogen production</div>
                                                    <div> - Delivery</div>
                                                    <div> - End uses</div>
                                                    <div> - Storage</div>
                                                    <div> - Conversion systems</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ) :
                            <div style={{height: "100%"}}
                                 className='text-center text-3xl lg:text-7xl flex items-center justify-center font-essentialSansBold w-full'>
                                <div className={"opacity-100"}>Investment Focus</div>
                            </div>}
                    </div>
                </div>
                <div className='relative' onClick={() => onClickHeading("company_stage")}>
                    <img
                        src={innovators_company_stage}
                        alt="" className='w-full hidden md:block'
                    />
                    <img
                        src={innovators_company_stage_m}
                        alt="" className='w-full md:hidden'
                    />
                    <div style={{height: "100%"}}
                         className={`absolute top-0  text-center w-full justify-center flex bg-deep  ${heading === "company_stage" ? "opacity-70" : "opacity-30"}`}
                    />
                    <div style={{height: "100%"}} className="absolute top-0  text-center w-full justify-center flex">
                        {heading === "company_stage" ? (
                                <div style={{height: "100%"}}
                                     className='text-base leading-5 text-justify px-6 lg:p-0 lg:text-2xl lg:text-left lg:w-1/2 flex items-center justify-center font-essentialSansLight w-full'>
                                    We invest in seed and early stage startups and companies
                                    driving innovation in the “hydrogen economy.” We bring
                                    unsurpassed expertise in hydrogen with industry-leading
                                    advisors and access to the best minds in the business.
                                </div>
                            ) :
                            <div style={{height: "100%"}}
                                 className='text-center text-3xl lg:text-7xl flex items-center justify-center font-essentialSansBold w-full'>
                                <div className={"opacity-100"}>Company Stage</div>
                            </div>}
                    </div>
                </div>
                <div className='relative' onClick={() => onClickHeading("investment_size")}>
                    <img
                        src={innovators_investment_size}
                        alt="" className='w-full hidden md:block'
                    />
                    <img
                        src={innovators_investment_size_m}
                        alt="" className='w-full md:hidden'
                    />
                    <div style={{height: "100%"}}
                         className={`absolute top-0  text-center w-full justify-center flex bg-deep  ${heading === "investment_size" ? "opacity-70" : " opacity-30"}`}
                    />
                    <div style={{height: "100%"}} className="absolute top-0  text-center w-full justify-center flex">
                        {heading === "investment_size" ? (
                                <div style={{height: "100%"}}
                                     className='text-base leading-5  text-justify px-6 lg:p-0 lg:text-2xl lg:text-left lg:w-1/2 flex items-center justify-center font-essentialSansLight w-full'>
                                    Our target investments range from $1 million to $10 million,
                                    over several rounds.
                                </div>
                            ) :
                            <div style={{height: "100%"}}
                                 className='text-center text-3xl lg:text-7xl flex items-center justify-center font-essentialSansBold w-full'>
                                <div className={"opacity-100"}>Investment Size</div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
