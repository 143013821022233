export const BIOS = {
    person_1: {
        name: "Steven Worzman",
        bio: [
            "Steven Worzman has over 30 years of experience working with start-up and fast growth businesses as a venture capitalist, chief financial officer at a venture capital firm, chief financial officer of various early and mature stage companies, and as a chemical engineer in industry. With expertise in both business and engineering he combines private equity, finance and accounting, and operations proficiency with an in-depth understanding of many technologies and industrial processes.",
            "He served for three years as the chief financial officer for NGEN Partners, LLC, a venture capital company specializing in alternative energy, clean-tech, and material science technologies. As a venture capitalist for eight years, he was Senior Vice President of Bartlett, Schlumberger and Company, Inc., a private investment company of the Schlumberger family, and EAB Venture, Inc., a venture capital subsidiary of European American Bank. He was also the chief financial officer for three growth companies, including: SkyPipeline, Inc. – a Wi-Fi broadcast Internet service provider, Bargain Network, Inc. – a direct response marketer, and Jazz Air, Inc. – a passenger airline. He served as the Executive Director for Santa Barbara’s Central Coast Venture Forum 2000, an annual conference for emerging-growth companies and venture capital investors.",
            "Previously, Mr. Worzman formed and operated Radius Capital, LLC, focused on financing alternatives for public U.S. companies via SEC Reg. S and Reg. D private placements. In his early career, he was a management and information technology consultant for Peat, Marwick, Mitchell & Co., a consulting chemical engineer for Gordian Associates, Inc. (a subsidiary of Pullman-Kellogg, Inc.), and a process engineer for E.I. DuPont de Nemours and Company.",
            "Mr. Worzman graduated with an MBA from The Wharton School and a BS in Chemical Engineering from Carnegie- Mellon University. He has also completed courses in Web Technologies and Web Server Programming at Santa Barbara City College, Mergers and Acquisitions at UCLA, Corporate Taxation, Individual Income Taxation, and E-Commerce at the University of California, Santa Barbara (UCSB). Mr. Worzman has lectured on cash flow modeling for UCSB’s Technology Management Program and on venture capital for the international MBA program of the Institut Supérieur de Gestion (ISG) in New York."
        ],
        email: "steven.worzman@hydrogenpowerpartners.com"
    },

    person_2: {
        name: "Stephen MacIntosh",
        bio: [
            "Stephen MacIntosh is an SEC-registered investment advisor representative (IAR), working as a fiduciary with \n" +
            "expertise in financial planning, Environment, Society and Governance (ESG) Investing and Impact Investing. He \n" +
            "holds the professional designation of Certified Wealth Strategist (CWS) and maintains a Series 66 license, \n" +
            "administered by the North American Securities Administrators Association (NASAA), an international organization \n" +
            "devoted to investor protection. Mr. MacIntosh constructs and manages diversified investment portfolios utilizing \n" +
            "both publicly traded securities and private placements, applying a seasoned understanding of ESG criteria.  Prior \n" +
            "to working as an investment adviser, he spent several years helping his clients under a broker dealer model \n" +
            "(regional wire-house) as a financial adviser.\n",
            "Mr. MacIntosh is a founding board member and immediate past president of the Sustainable Change Alliance (SCA) \n" +
            "Foundation, a non-profit organization that educates and promotes impact investing in Southern California. He has \n" +
            "served on the board of directors of a dozen 501(c)3 organizations, in various capacities, holding leadership roles in \n" +
            "development, advancement and financial oversight. Earlier in his career, Stephen managed business units in Southern \n" +
            "California for a Fortune 300 corporation, founded and operated a consulting firm providing sustainability audits and \n" +
            "public relations services, served as a division head in local government and worked in education in California and \n" +
            "internationally.\n",
            "Mr. MacIntosh holds a master’s degree from the School of Public and Environmental Affairs at Indiana University, \n" +
            "and a bachelor’s degree from Revelle College at the University of California at San Diego."
        ],
        email: "stephen.macintosh@hydrogenpowerpartners.com\n"
    },
    person_3: {
        name: "Ivor John",
        bio: [
            "Ivor John is an atmospheric physicist by training and, for most of his career, he worked as an environmental \n" +
            "consultant and auditor. Throughout his career, Dr. John has specialized in air quality, climate change and the \n" +
            "analysis of air emissions and Greenhouse Gases.",
            "He has worked in a broad range of industry sectors, primarily with energy, and his clients have included oil \n" +
            "and gas and utility corporations, as well as municipalities and government agencies. This work has included \n" +
            "consulting services, and auditing against well-established international standards such as ISO 14001 \n" +
            "(Environmental Management Standard) and ISO 14064 (Greenhouse Gases). During this period, he served on \n" +
            "the American National Stands Institute (ANSI) Accreditation Committee for Climate Change assessors and \n" +
            "chaired this committee for several years.",
            "Dr. John was a founder and manager of a startup company that focused on the application of low-emissions \n" +
            "technologies for heavy duty vehicles and fleet services. He managed one of the first large battery electric fleet \n" +
            "deployments for the US Postal Service, and he has been a leader in the field of GHG emissions verification \n" +
            "services when quantification and reporting became a requirement for many businesses. He subsequently \n" +
            "audited several hundred facilities and transitioned this role into auditing of Corporate EHS management systems.\n",
            "Dr. John has been a follower and advocate of hydrogen technology advancement for over ten years and has \n" +
            "awareness of the significant opportunities before us. He has a unique understanding of the technologies used \n" +
            "across the hydrogen value chain, including production, storage, distribution, and end use. He recently led the \n" +
            "development of Hydrogen Readiness Plan for the Central Coast Region of California. He maintains close contact \n" +
            "with many professional colleagues associated with hydrogen.\n",
            "Dr. John received his Bachelor of Science (BSc) degree in Meteorology and Physics from the University of Reading, \n" +
            "and his Doctor of Philosophy (PhD) in Physics of the Atmosphere from the University of Wales, Aberystwyth.\n"
        ],
        email: "ivor.john@hydrogenpowerpartners.com\n"
    },
    person_4: {
        name: "Jack Brouwer, Ph.D.",
        bio: [
            "Jack Brouwer is a professor of Mechanical and Aerospace Engineering, Civil and Environmental Engineering, and Chemical and Biomolecular Engineering at the University of California Irvine.  He also serves as the director of the National Fuel Cell Research Center’s Advanced Power and Energy Program at UC Irvine.",
            "Professor Brouwer is an energy system dynamics expert with research interests in renewable energy systems; dynamic simulation and control; energy system thermodynamics, design, and integration; electrochemical conversion devices and systems such as fuel cells, electrolyzers and batteries; hydrogen production, storage and conversion systems; hydrogen transmission, distribution, and dispensing; and electrochemical reactions with concurrent heat, mass and momentum transfer. ",
            "Professor Brouwer obtained his M.S. and B.S. in Mechanical Engineering from UC Irvine and his Ph.D. in Mechanical Engineering from Massachusetts Institute of Technology."
        ],
    },
    person_5: {
        name: "Jonathan Gartner",
        bio: [
            "Jonathan Gartner is a highly seasoned operating professional with experience across Asia, Europe and North America \n" +
            "who is now focusing his efforts on leadership roles in the field of sustainable investments.\n",
            "Gartner spent the bulk of his career in wholesale banking, working first for domestic (LaSalle Bank and First National \n" +
            "Bank of Chicago) and then international (ABN Amro) organizations in client facing and senior management roles. \n" +
            "Gartner subsequently became the founding COO of Navis Capital, the preeminent Asia based and focused private \n" +
            "equity firm; the chief investment advisor for Ping, an independent energy company; and the COO for LEAP Innovations, \n" +
            "a non-profit working at the nexus of education and technology.\n",
            "Gartner now dedicates his time to impact investing primarily through the Sustainable Change Alliance. Gartner \n" +
            "currently lives in Southern California after living and working abroad for the past two decades.\n",
            "MBA, Kellogg Graduate School of Management, Northwestern University, 1985 - Evanston, IL\n" +
            "BA, Hamilton College, 1980 - Clinton, NY\n" +
            "Earned US Securities licenses (Series 7, 24, and 63) as well as HKMA Type 1"
        ],
        email: "steven.worzman@hydrogenpowerpartners.com"
    },
    person_6: {
        name: "Alan C. Lloyd, Ph.D.",
        bio: [
            "Senior Research Fellow, Energy Institute, University of Texas at Austin.\n",
            "President Emeritus, International Council on Clean Transportation\n",
            "San Francisco CA, Washington DC – USA\n",
            "Dr. Lloyd is currently a Senior Research Fellow at the Energy Institute, University of Texas at Austin. The focus of his \n" +
            "research is the role of hydrogen in a sustainable society, fuel cells, electric drive vehicles, renewable energy, and \n" +
            "policies to promote zero emissions vehicles.\n",
            "Alan C. Lloyd served as the President of the International Council on Clean Transportation from 2006 until 2013. He is \n" +
            "one of the founding members of the ICCT. The ICCT, sponsored by the William and Floral Hewlett Foundation, the \n" +
            "Energy Foundation, and the ClimateWorks Foundation, has the goal of dramatically improving the environmental \n" +
            "performance and efficiency of cars, trucks, buses, and transportation systems in order to protect and improve public \n" +
            "health, environment and quality of life.\n" +
            "\n",
            "Dr. Lloyd served as the Secretary of the California Environmental Protection Agency. Appointed by Governor Arnold \n" +
            "Schwarzenegger in December 2004.  Among other initiatives, he required a review of the “State of the Science” of the \n" +
            "six Boards and Departments composing the Agency in order to ensure that it met the quality required for sound policy \n" +
            "making. Oversaw a budget of $1.5 billion and 4,500 employees throughout California. The mission of the California \n" +
            "Environmental Protection Agency is to ensure public health, environmental quality, and economic vitality in the State \n" +
            "of California. It is composed of the following boards, departments, and offices: Air Resources Board, Department of \n" +
            "Pesticide Regulation, Department of Toxic Substances Control, Integrated Waste Management Board, Office of \n" +
            "Environmental Health Hazard Assessment, and the State Water Resources Control Board.",
            "As Secretary, Dr. Lloyd led the Governor’s Climate Action Team (CAT) to develop a plan to implement the Governor’s \n" +
            "aggressive Executive Order to reduce greenhouse gas emissions in California to 80% of 1990 levels. He chaired the \n" +
            "California Greenhouse Gas Registry, was a member of the Oceans Protection Council, chaired the Environmental \n" +
            "Justice Agency Working Group and was a co-chair of the Goods Movement Action Plan requested by Governor \n" +
            "Schwarzenegger. Dr. Lloyd led the California Climate Change delegation at the United Nations Climate Convention \n" +
            "in Montreal in 2005 and developed a Memorandum of Understanding in collaboration with Professor José Goldemberg \n" +
            "to share experiences in reducing greenhouse gases in the states of São Paulo, Brazil, and California, United States.\n",
            "Dr. Lloyd has given many presentations to national and international audiences, focusing on the viable future of \n" +
            "advanced technology and renewable fuels, with attention to the urban air quality challenges faced by California and to \n" +
            "the impact on global climate change. He is a major proponent of efficiency, alternate fuels, electric drive and fuel cell \n" +
            "vehicles. Dr. Lloyd has also authored many articles on alternative fuels and air pollution control technology, including \n" +
            "fuel Cells and Air Quality: A California Perspective; Electric Vehicles and Future Air Quality in Los Angeles; Air Quality \n" +
            "Management in Lost Angeles: Perspectives on Past and Future Emission Control Strategies; and Accelerating Mobile \n" +
            "Source Emission Reductions: California’s Experience and Recommendations to Developing Countries.\n",
            "Prior to joining CARB, Dr. Lloyd was the Executive Director of the Energy and Environmental Engineering Center for \n" +
            "the Desert Research Institute at the University and Community College System of Nevada, Reno, and the chief scientist \n" +
            "at the South Coast Air Quality Management District from 1988 to 1996.\n",
            "Dr. Lloyd’s work focuses on the viable future of advanced technology and renewable fuels, with attention to urban \n" +
            "air quality issues and global climate change. He is a proponent of efficiency, alternate and renewable fuels, and \n" +
            "electric drive and fuel cell vehicles eventually leading to a zero-emissions transportation system.",
            "Dr. Lloyd earned both his B.S. in Chemistry and Ph.D. in Gas Kinetics at the University College of Wales, \n" +
            "Aberystwyth, U.K.\n"
        ],
    },
    person_7: {
        name: "David Raney",
        bio: [
            "David Raney spent 38 years in the auto industry with Saab-Scania, General Motors, Honda, and Toyota managing the \n" +
            "environmental and energy affairs and regulatory compliance for those firms in North America. He was intimately \n" +
            "involved in the development of product strategies for fuel economy / GHG compliance as well as battery and hydrogen \n" +
            "fuel cell vehicles at Honda and Toyota.\n",
            "During his career, he was a Presidential appointee to the Federal Clean Air Act Advisory Committee, the EPA’s Mobile \n" +
            "Source Technical Research Subcommittee and led an industry team to China to advise the Chinese government on \n" +
            "automotive fuel quality in 2006. He has also taught a course on Motor Vehicle Public Policy as an Adjunct Professor \n" +
            "at UCSB’s Bren Graduate School of Environmental Science and Management.",
            "Since retiring from the auto industry in 2020, he founded and is currently the Executive Director of the Texas \n" +
            "Hydrogen Alliance, an industry-based education and policy advocacy non-profit firm. He also provides consulting \n" +
            "services to the broader energy sector with a focus on renewables and hydrogen.\n",
            "David earned a BSME in 1980 from the University of Oklahoma and an Executive MBA from UCLA in 2007.\n"
        ],
    }
}
