import {
    alan_c_lloyd, david_raney,
    hpp_home,
    ivor_john,
    jack_brouwer,
    jonathan_gartner,
    stephen_macIntosh,
    steven_worzman
} from "../images_cdn";
import Bio from "../componests/Bio";
import {BIOS} from "../bio";
import {useState, useRef} from "react";

export default function HPPScreen() {
    const [selected, setSelected] = useState(null)
    const [top, setTop] = useState(false)
    const advisorRef = useRef(null)
    const advisorBoardRef = useRef(null)

    const moveBioPartners = () => {
        advisorRef.current?.scrollIntoView({behavior: 'smooth'});
    }
    const moveBioBoard = ()=> {
        advisorBoardRef.current?.scrollIntoView({behavior: 'smooth'});

    }
    const showBio = (id) => {
        if (selected) {
            const element = document.getElementById(selected)
            element.classList.toggle("grayscale")
        }
        setTop(id.includes("1") || id.includes("2") || id.includes("3"))
        setSelected(id)
        const element = document.getElementById(id)
        element.classList.toggle("grayscale")
        if(id.includes("1") || id.includes("2") || id.includes("3")) {
            moveBioPartners()
        }else{
            moveBioBoard()
        }
    }

    return (
        <div>
            <div className={'w-full'}>
                <img className={'w-full'} src={hpp_home} alt=""/>
            </div>
            <div
                className='lg:flex text-white items-center justify-center md:py-36 py-10 lg:pt-26 lg:pb-28 bg-deep font-essentialSans lg:px-20'>
                <div className='lg:w-[40%] text-center lg:text-6xl font-bold text-3xl'>
                    Our Team
                </div>
                <div className='text-justify lg:w-[60%]  lg:text-3xl px-6 lg:pl-10 lg:py-10 lg:py-0 p-3 '>
                    Hydrogen Power Partners, LP is a new venture fund
                    focused exclusively on clean hydrogen technology. Our
                    founders and advisory board are deeply experienced venture
                    capitalists, sustainability investors, and some of the world’s
                    preeminent hydrogen energy researchers, scientists, and
                    industry leaders. We are committed to supporting early stage
                    companies and startups developing innovative solutions in
                    hydrogen production and use.
                </div>
            </div>
            <div className={'py-10 lg:py-[6.25rem] w-full text-center text-3xl lg:text-6xl font-semibold bg-brown text-white'}>
                Meet Our Partners
            </div>
            <div className='lg:flex text-white text-3xl'>
                <div className='lg:w-1/3 relative'>
                    <img
                        id="person_1"
                        onClick={() => showBio("person_1")}
                        src={steven_worzman}
                        alt="" className='grayscale hover:grayscale-0 w-full'/>
                    <div className="absolute top-[70%] md:top-[70%] w-full  justify-center">
                        <div className='text-center font-essentialSansBold'>Steven Worzman</div>
                    </div>
                </div>
                <div id={"2"} className='lg:w-1/3 relative'>
                    <img
                        id="person_2"
                        onClick={() => showBio("person_2")}
                        src={stephen_macIntosh}
                        alt="" className='grayscale hover:grayscale-0 w-full'/>
                    <div className="absolute top-[70%] md:top-[70%] w-full  justify-center">
                        <div className='text-center font-essentialSansBold'>Stephen MacIntosh</div>
                    </div>
                </div>
                <div id={"3"} className='lg:w-1/3 relative'>
                    <img
                        id="person_3"
                        onClick={() => showBio("person_3")}
                        src={ivor_john}
                        alt="" className='grayscale hover:grayscale-0 w-full'/>
                    <div className="absolute top-[70%] md:top-[70%] w-full  justify-center">
                        <div className='text-center font-essentialSansBold'>Ivor John</div>
                    </div>
                </div>
            </div>
            <div ref={advisorRef}/>
            {selected && top ? <Bio contant={BIOS[selected]}/> : ""}
            <div className='py-10 lg:py-[6.25rem] w-full text-center text-3xl lg:text-6xl font-semibold bg-brown text-white'>
                Meet our Advisory Board
            </div>
            <div className='md:flex text-3xl text-white'>
                <div className='relative lg:w-1/4'>
                    <img
                        id="person_4"
                        onClick={() => showBio("person_4")}
                        src={jack_brouwer}
                        alt="" className='grayscale hover:grayscale-0 w-full'/>
                    <div className="absolute top-[70%] md:top-[70%] w-full  justify-center">
                        <div className='text-center font-essentialSansBold'>Jack Brouwer, Ph.D.</div>
                    </div>
                </div>
                <div className='relative lg:w-1/4'>
                    <img
                        id="person_5"
                        onClick={() => showBio("person_5")}
                        src={jonathan_gartner}
                        alt="" className='grayscale hover:grayscale-0 w-full'/>
                    <div className="absolute top-[70%] md:top-[70%] w-full  justify-center">
                        <div className='text-center font-essentialSansBold'>Jonathan Gartner</div>
                    </div>
                </div>
                <div className='relative lg:w-1/4'>
                    <img
                        id="person_6"
                        onClick={() => showBio("person_6")}
                        src={alan_c_lloyd}
                        alt="" className='grayscale hover:grayscale-0 w-full'/>
                    <div className="absolute top-[70%] md:top-[70%] w-full  justify-center">
                        <div className='text-center font-essentialSansBold'>Alan C. Lloyd, Ph.D.</div>
                    </div>
                </div>
                <div className='relative lg:w-1/4'>
                    <img
                        id="person_7"
                        onClick={() => showBio("person_7")}
                        src={david_raney}
                        alt="" className='grayscale hover:grayscale-0 w-full'/>
                    <div className="absolute top-[70%] md:top-[70%] w-full  justify-center">
                        <div className='text-center font-essentialSansBold'>David Raney</div>
                    </div>
                </div>
            </div>
            <div ref={advisorBoardRef}/>
            {selected && !top ? <Bio contant={BIOS[selected]}/> : ""}
        </div>
    )
}
