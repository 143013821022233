export default function Bio({contant}) {

    return (
        <div className="p-6 md:p-28">
            <div className="text-deep font-bold text-xl">{contant.name}</div>
            <div className="font-editor text-justify md:text-left font-light text-bio text-lg tracking-wider	">
                {contant.bio.map(item => <div className="mt-10">{item}</div>)}
            </div>
            {contant.email ? <div className={"mt-10 text-deep font-semibold"}>{contant.email}</div> : ""}
        </div>
    )
}
