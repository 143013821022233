import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import HomeScreen from "./screen/HomeScreen";
import Navbar from "./componests/Navbar";
import Footer from "./componests/Footer";
import HPPScreen from "./screen/HPPScreen";
import InnovatorsScreen from "./screen/InnovatorsScreen";
import InvestorsScreen from "./screen/InvestorsScreen";
import ContactScreen from "./screen/ContactScreen";
import HydrogenScreen from "./screen/HydrogenScreen";
import ScrollToTop from "./componests/ScrollToTop";
import TOU from "./screen/TOU";
import PP from "./screen/PP";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Navbar/>
                <Routes>
                    <Route index element={<HomeScreen/>}/>
                    <Route path='/hpp' element={<HPPScreen/>}/>
                    <Route path='/innovators' element={<InnovatorsScreen/>}/>
                    <Route path='/investors' element={<InvestorsScreen/>}/>
                    <Route path='/contact' element={<ContactScreen/>}/>
                    <Route path='/hydrogen' element={<HydrogenScreen/>}/>
                    <Route path='/term-of-use' element={<TOU/>}/>
                    <Route path='/privacy-policy' element={<PP/>}/>
                </Routes>
            <Footer/>
        </BrowserRouter>
    )
}

export default App;
