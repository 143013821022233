const home_tl = 'https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,q_80,w_1500/v1667926399/hpp/home_hpp_rog1ja.jpg'
const home_tr = 'https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_1500/v1667926132/hpp/home_investors_zeebe3.jpg'
const home_bl = 'https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_1500/v1667926204/hpp/home_innovators_i8lq0a.jpg'
const home_br = 'https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_1500/v1667926213/hpp/home_hydrogen_iery3e.jpg'
const hpp_home = "https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,q_80,w_3500/v1667926399/hpp/home_hpp_rog1ja.jpg"
const alan_c_lloyd = 'https://res.cloudinary.com/dfdvvquoy/image/upload/v1667937965/alan_c_lloyd_a70htg.jpg'
const david_raney = 'https://res.cloudinary.com/dfdvvquoy/image/upload/v1667937973/david_raney_vtbepc.jpg'
const ivor_john = 'https://res.cloudinary.com/dfdvvquoy/image/upload/v1667937998/ivor_john_khm65a.jpg'
const jack_brouwer = 'https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938002/jack_brouwer_qxieir.jpg'
const jonathan_gartner = 'https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_1500/v1667938042/jonathan_gartner_tqlsaa.jpg'
const stephen_macIntosh = 'https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938080/stephen_macIntosh_my3xzm.jpg'
const steven_worzman = 'https://res.cloudinary.com/dfdvvquoy/image/upload/v1667938106/steven_worzman_voau5y.jpg'
const investors_main = 'https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_3500/v1667839844/investors_main_ayorb9.jpg'
const investors_secondary = "https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,h_1436,w_1915/v1667937052/hpp/investors_secondary_zimatx.jpg"
const innovators_company_stage = "https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_3000/v1667928120/hpp/innovators_company_stage_to8pba.jpg"
const innovators_investment_size = "https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_3000/v1667928124/hpp/innovators_investment_size_pobjty.jpg"
const innovators_cover = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667928079/hpp/innovators_cover_myyypy.jpg"
const innovators_investment_focus = "https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_3000/v1667928136/hpp/innovators_investment_focus_pqzvwv.jpg"
const innovators_company_stage_m = "https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_1500/v1667928104/hpp/innovators_company_stage_m_gulnfb.jpg"
const innovators_investment_focus_m = "https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_1500/v1667928112/hpp/innovators_investment_focus_m_seaykh.jpg"
const innovators_investment_size_m = "https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,w_1500/v1667928112/hpp/innovators_investment_size_m_ob6dqj.jpg"
const home_video = "https://res.cloudinary.com/dfdvvquoy/video/upload/vc_auto/v1667938795/home_1_iujezn.mp4"
const FOOTER_TEXTURE = 'https://res.cloudinary.com/dfdvvquoy/image/upload/c_scale,q_30,w_2000/v1667926187/hpp/footer_textture_at4kpx.jpg'
const FOOTER_LOGO = 'https://res.cloudinary.com/dfdvvquoy/image/upload/v1667927752/hpp/footer_logo_tourup.png'
const LOGO = "https://res.cloudinary.com/dfdvvquoy/image/upload/v1667937142/hpp/Logo_vzhubt.png"
export {
    hpp_home,
    LOGO,
    FOOTER_TEXTURE,
    FOOTER_LOGO,
    innovators_cover,
    innovators_investment_size,
    innovators_investment_focus,
    innovators_company_stage,
    innovators_investment_size_m,
    innovators_investment_focus_m,
    innovators_company_stage_m,
    home_tl,
    home_tr,
    home_br,
    home_bl,
    home_video,
    alan_c_lloyd,
    david_raney,
    ivor_john,
    jack_brouwer,
    jonathan_gartner,
    steven_worzman,
    stephen_macIntosh,
    investors_main,
    investors_secondary
}
