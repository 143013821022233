import {Link} from "react-router-dom";
import {FOOTER_LOGO, FOOTER_TEXTURE, LOGO} from "../images_cdn";


function Footer() {
    return (
        <div style={{backgroundImage: `url(${FOOTER_TEXTURE})`, backgroundSize: "fill"}}>
            <div className="pt-10 md:pt-24 pb-10" style={{background: "rgba(200, 200, 200, .6)", height: "100%"}}>
                <div className={"lg:flex"}>
                    <div className={"lg:w-1/2"}/>
                    <div
                        className={"flex w-full lg:w-1/2 text-[1rem] flex-col lg:flex-row text-center justify-center lg:justify-between  lg:pr-10"}
                        style={{letterSpacing: "0.15rem"}}
                    >
                        <Link to="/" className={"pt-2 lg:p-0"}>
                            <div>HOME</div>
                        </Link>
                        <Link to="hpp" className={"pt-2 lg:p-0"}>
                            <div>HPP</div>
                        </Link>
                        <Link to="investors" className={"pt-2 lg:p-0"}>
                            <div>INVESTORS</div>
                        </Link>
                        <Link to="innovators" className={"pt-2 lg:p-0"}>
                            <div>INNOVATORS</div>
                        </Link>
                        <Link to="hydrogen" className={"pt-2 lg:p-0"}>
                            <div>HYDROGEN</div>
                        </Link>
                        <Link to="contact" className={"pt-2 lg:p-0"}>
                            <div>CONTACT US</div>
                        </Link>
                    </div>
                </div>
                <div>
                    <div
                        className=' text-center lg:w-1/2 font-essentialSans py-8 md:py-14 mt-0 lg:-mt-10'>
                        <div className={'w-full grid place-content-center '}>
                            <img className='h-12 lg:h-auto'
                                 src={LOGO}
                                 alt="asd"
                            />
                        </div>
                        <div className={'w-full grid place-content-center -mt-6 md:-mt-13'}>
                            <img className='h-32 lg:h-auto'
                                 src={FOOTER_LOGO}
                                 alt="asd"/>
                        </div>
                        <div
                            className={'text-base lg:text-3xl  font-bold -mt-3 lg:mt-10 text-gray font-essentialSansLight'}
                            style={{letterSpacing: "1px"}}>
                            <div><span className={"font-essentialSansMedium"}>Critical</span> Challenges.</div>
                            <div> Historic <span className={"font-essentialSansMedium"}>Opportunities.</span></div>
                        </div>
                    </div>
                    <div
                        className='grid place-content-center text-center font-essentialSans text-[14px] mt-10  md:mt-20'
                        style={{letterSpacing: "1px"}}>
                        <div className='w-full'>
                            <div>Copyright 2022 Hydrogen Power Partners LLC</div>
                            <div><Link to={"/privacy-policy"}>Privacy Policy</Link> |<Link to={"/term-of-use"}> Terms of Use </Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
