export default function PP() {

    return (
        <div
            className={"px-6 text-justify lg:text-left lg:px-40 pb-10 flex-col space-y-5 text-gray2 font-essentialSans"}>
            <p className={"font-essentialSansMedium text-3xl"}>PRIVACY NOTICE</p>

            <p className={"pb-5 pt-3"}>Last updated&#160;October 10, 2022</p>

            <p>This privacy notice for&#160;HPP,
                LLC&#160;(&quot;Company&#160;,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;&#160;our&quot;),
                describes how and why we might collect, store, use, and/or share (&quot;process&quot;) your information
                when you use our services (&quot;&#160;Services&quot;), such as when you:  &bull; Visit our
                website&#160;at&#160;http://www.hydrogenpowerpartners.com&#160;, or any website of ours that links to
                this privacy notice</p>

            <p> &bull; Engage with us in other related ways, including any sales, marketing, or events Questions or
                concerns?&#160;Reading this privacy notice will help you understand your privacy rights and choices. If
                you do not agree with our policies and practices, please do not use our Services. If you still have any
                questions or concerns, please contact us at&#160;stephen.macintosh@hydrogenpowerpartners.com.</p>

            <p className={"font-essentialSansMedium"}>SUMMARY OF KEY POINTS</p>

            <p>This summary provides key points from our privacy notice, but you can find out more details about any of
                these topics by clicking the link following each key point or by using our table of contents below to
                find the section you are looking for. You can also click&#160;here&#160;to go directly to our table of
                contents.</p>

            <p>What personal information do we process?&#160;When you visit, use, or navigate our Services, we may
                process personal information depending on how you interact with&#160;HPP, LLC&#160;and the Services, the
                choices you make, and the products and features you use. Click&#160;here&#160;to learn more.</p>

            <p>Do we process any sensitive personal information?&#160;We do not process sensitive personal
                information.</p>

            <p>Do we receive any information from third parties?&#160;We do not receive any information from third
                parties.</p>

            <p>How do we process your information?&#160;We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud prevention, and to comply with law. We may
                also process your information for other purposes with your consent. We process your information only
                when we have a valid legal reason to do so. Click&#160;here&#160;to learn more.</p>

            <p>In what situations and with which&#160;parties do we share personal information?&#160;We may share
                information in specific situations and with specific&#160;third parties. Click&#160;here&#160;to learn
                more.</p>

            <p>How do we keep your information safe?&#160;We have&#160;organizational&#160;and technical processes and
                procedures in place to protect your personal information. However, no electronic transmission over the
                internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other&#160;unauthorized&#160;third parties will not be able
                to defeat our security and improperly collect, access, steal, or modify your information.
                Click&#160;here&#160;to learn more.</p>

            <p>What are your rights?&#160;Depending on where you are located geographically, the applicable privacy law
                may mean you have certain rights regarding your personal information. Click&#160;here&#160;to learn
                more.</p>

            <p>How do you exercise your rights?&#160;The easiest way to exercise your rights is by filling out our data
                subject request form available&#160;here:&#160;http://www.hydrogenpowerpartners.com/contact, or by
                contacting us. We will consider and act upon any request in accordance with applicable data protection
                laws.</p>

            <p>Want to learn more about what&#160;HPP, LLC&#160;does with any information we collect?
                Click&#160;here&#160;to review the notice in full.</p>

            <p className={"font-essentialSansMedium"}>TABLE OF CONTENTS</p>

            <p>1. WHAT INFORMATION DO WE COLLECT? 2. HOW DO WE PROCESS YOUR INFORMATION? 3.&#160;WHAT LEGAL BASES DO WE
                RELY ON TO PROCESS YOUR PERSONAL INFORMATION? 4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                INFORMATION? 5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? 6. HOW LONG DO WE KEEP YOUR
                INFORMATION? 7. HOW DO WE KEEP YOUR INFORMATION SAFE? 8. DO WE COLLECT INFORMATION FROM MINORS? 9. WHAT
                ARE YOUR PRIVACY RIGHTS? 10. CONTROLS FOR DO-NOT-TRACK FEATURES 11. DO CALIFORNIA RESIDENTS HAVE
                SPECIFIC PRIVACY RIGHTS? 12. DO WE MAKE UPDATES TO THIS NOTICE? 13. HOW CAN YOU CONTACT US ABOUT THIS
                NOTICE? 14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>

            <p>1. WHAT INFORMATION DO WE COLLECT?</p>

            <p>Personal information you disclose to us</p>

            <p>In Short:&#160;We collect personal information that you provide to us.</p>

            <p>We collect personal information that you voluntarily provide to us when you&#160;express an interest in
                obtaining information about us or our products and Services, when you participate in activities on the
                Services, or otherwise when you contact us.</p>

            <p> Personal Information Provided by You.&#160;The personal information that we collect depends on the
                context of your interactions with us and the Services, the choices you make, and the products and
                features you use. The personal information we collect may include the following:  &bull; email
                addresses</p>

            <p> &bull; names</p>

            <p>Sensitive Information.&#160;We do not process sensitive information.</p>

            <p> All personal information that you provide to us must be true, complete, and accurate, and you must
                notify us of any changes to such personal information.</p>

            <p> Information automatically collected</p>

            <p>In Short:&#160;Some information &acirc;&euro;&rdquo; such as your Internet Protocol (IP) address and/or
                browser and device characteristics &acirc;&euro;&rdquo; is collected automatically when you visit our
                Services.</p>

            <p>We automatically collect certain information when you visit, use, or navigate the Services. This
                information does not reveal your specific identity (like your name or contact information) but may
                include device and usage information, such as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device name, country, location, information
                about how and when you use our Services, and other technical information. This information is primarily
                needed to maintain the security and operation of our Services, and for our internal analytics and
                reporting purposes.</p>

            <p> Like many businesses, we also collect information through cookies and similar technologies.</p>

            <p> The information we collect includes:  &bull; Log and Usage Data.&#160;Log and usage data is
                service-related, diagnostic, usage, and performance information our servers automatically collect when
                you access or use our Services and which we record in log files. Depending on how you interact with us,
                this log data may include your IP address, device information, browser type, and settings and
                information about your activity in the Services&#160;(such as the date/time stamps associated with your
                usage, pages and files viewed, searches, and other actions you take such as which features you use),
                device event information (such as system activity, error reports (sometimes called&#160;&quot;crash
                dumps&quot;), and hardware settings).</p>

            <p> &bull; Device Data.&#160;We collect device data such as information about your computer, phone, tablet,
                or other device you use to access the Services. Depending on the device used, this device data may
                include information such as your IP address (or proxy server), device and application identification
                numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier,
                operating system, and system configuration information.</p>

            <p> &bull; Location Data.&#160;We collect location data such as information about your device's location,
                which can be either precise or imprecise. How much information we collect depends on the type and
                settings of the device you use to access the Services. For example, we may use GPS and other
                technologies to collect geolocation data that tells us your current location (based on your IP address).
                You can opt out of allowing us to collect this information either by refusing access to the information
                or by disabling your Location setting on your device. However, if you choose to opt out, you may not be
                able to use certain aspects of the Services.</p>

            <p className={"font-essentialSansMedium"}>2. HOW DO WE PROCESS YOUR INFORMATION?</p>

            <p>In Short:&#160;We process your information to provide, improve, and administer our Services, communicate
                with you, for security and fraud prevention, and to comply with law. We may also process your
                information for other purposes with your consent.</p>

            <p>We process your personal information for a variety of reasons, depending on how you interact with our
                Services, including:</p>

            <p></p>

            <p></p>

            <p> &bull; To save or protect an individual's vital interest.&#160;We may process your information when
                necessary to save or protect an individual&acirc;&euro;&trade;s vital interest, such as to prevent
                harm.</p>

            <p className={"font-essentialSansMedium"}> 3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                INFORMATION?</p>

            <p>In Short:&#160;We only process your personal information when we believe it is necessary and we have a
                valid legal reason (i.e.,&#160;legal basis) to do so under applicable law, like with your consent, to
                comply with laws, to provide you with services to enter into or&#160;fulfill&#160;our contractual
                obligations, to protect your rights, or to&#160;fulfill&#160;our legitimate business interests.</p>

            <p>If you are located in the EU or UK, this section applies to you.</p>

            <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we
                rely on in order to process your personal information. As such, we may rely on the following legal bases
                to process your personal information:  &bull; Consent.&#160;We may process your information if you have
                given us permission (i.e.,&#160;consent) to use your personal information for a specific purpose. You
                can withdraw your consent at any time. Click&#160;here&#160;to learn more.</p>

            <p> &bull; Legal Obligations.&#160;We may process your information where we believe it is necessary for
                compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory
                agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in
                which we are involved.</p>

            <p> &bull; Vital Interests.&#160;We may process your information where we believe it is necessary to protect
                your vital interests or the vital interests of a third party, such as situations involving potential
                threats to the safety of any person.</p>

            <p> If you are located in Canada, this section applies to you.</p>

            <p>We may process your information if you have given us specific permission (i.e.,&#160;express consent) to
                use your personal information for a specific purpose, or in situations where your permission can be
                inferred (i.e.,&#160;implied consent). You can withdraw your consent at any time.
                Click&#160;here&#160;to learn more.</p>

            <p>In some exceptional cases, we may be legally permitted under applicable law to process your information
                without your consent, including, for example:  &bull; If collection is clearly in the interests of an
                individual and consent cannot be obtained in a timely way</p>

            <p> &bull; For investigations and fraud detection and prevention</p>

            <p> &bull; For business transactions provided certain conditions are met</p>

            <p> &bull; If it is contained in a witness statement and the collection is necessary to assess, process, or
                settle an insurance claim</p>

            <p> &bull; For identifying injured, ill, or deceased persons and communicating with next of kin</p>

            <p> &bull; If we have reasonable grounds to believe an individual has been, is, or may be victim of
                financial abuse</p>

            <p> &bull; If it is reasonable to expect collection and use with consent would compromise the availability
                or the accuracy of the information and the collection is reasonable for purposes related to
                investigating a breach of an agreement or a contravention of the laws of Canada or a province</p>

            <p> &bull; If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court
                relating to the production of records</p>

            <p> &bull; If it was produced by an individual in the course of their employment, business, or profession
                and the collection is consistent with the purposes for which the information was produced</p>

            <p> &bull; If the collection is solely for journalistic, artistic, or literary purposes</p>

            <p> &bull; If the information is publicly available and is specified by the regulations</p>

            <p className={"font-essentialSansMedium"}> 4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>

            <p>In Short:&#160;We may share information in specific situations described in this section and/or with the
                following&#160;third parties.</p>

            <p> We&#160;may need to share your personal information in the following situations:  &bull; Business
                Transfers.&#160;We may share or transfer your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of all or a portion of our business to
                another company.</p>

            <p className={"font-essentialSansMedium"}> 5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>

            <p>In Short:&#160;We may use cookies and other tracking technologies to collect and store your
                information.</p>

            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
                information. Specific information about how we use such technologies and how you can refuse certain
                cookies is set out in our Cookie Notice.</p>

            <p className={"font-essentialSansMedium"}>6. HOW LONG DO WE KEEP YOUR INFORMATION?</p>

            <p>In Short:&#160;We keep your information for as long as necessary to&#160;fulfill&#160;the purposes
                outlined in this privacy notice unless otherwise required by law.</p>

            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in
                this privacy notice, unless a longer retention period is required or permitted by law (such as tax,
                accounting, or other legal requirements).</p>

            <p>When we have no ongoing legitimate business need to process your personal information, we will either
                delete or&#160;anonymize&#160;such information, or, if this is not possible (for example, because your
                personal information has been stored in backup archives), then we will securely store your personal
                information and isolate it from any further processing until deletion is possible.</p>

            <p className={"font-essentialSansMedium"}>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>

            <p>In Short:&#160;We aim to protect your personal information through a system
                of&#160;organizational&#160;and technical security measures.</p>

            <p>We have implemented appropriate and reasonable technical and&#160;organizational&#160;security measures
                designed to protect the security of any personal information we process. However, despite our safeguards
                and efforts to secure your information, no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other&#160;unauthorized&#160;third parties will not be able to defeat our security
                and improperly collect, access, steal, or modify your information. Although we will do our best to
                protect your personal information, transmission of personal information to and from our Services is at
                your own risk. You should only access the Services within a secure environment.</p>

            <p className={"font-essentialSansMedium"}>8. DO WE COLLECT INFORMATION FROM MINORS?</p>

            <p>In Short:&#160;We do not knowingly collect data from or market to children under 18 years of age.</p>

            <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services,
                you represent that you are at least 18 or that you are the parent or guardian of such a minor and
                consent to such minor dependent&acirc;&euro;&trade;s use of the Services. If we learn that personal
                information from users less than 18 years of age has been collected, we will deactivate the account and
                take reasonable measures to promptly delete such data from our records. If you become aware of any data
                we may have collected from children under age 18, please contact us
                at&#160;stephen.macintosh@hydrogenpowerpartners.com&#160;.</p>

            <p className={"font-essentialSansMedium"}>9. WHAT ARE YOUR PRIVACY RIGHTS?</p>

            <p>In Short:&#160;In some regions, such as&#160;the European Economic Area (EEA), United Kingdom (UK), and
                Canada, you have rights that allow you greater access to and control over your personal
                information.&#160;You may review, change, or terminate your account at any time.</p>

            <p>In some regions (like&#160;the EEA, UK, and Canada), you have certain rights under applicable data
                protection laws. These may include the right (i) to request access and obtain a copy of your personal
                information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal
                information; and (iv) if applicable, to data portability. In certain circumstances, you may also have
                the right to object to the processing of your personal information. You can make such a request by
                contacting us by using the contact details provided in the section&#160;&quot;&#160;HOW CAN YOU CONTACT
                US ABOUT THIS NOTICE?&#160;&quot;&#160;below.</p>

            <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>

            <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                information, you also have the right to complain to your local data protection supervisory authority.
                You can find their contact details here:&#160;https://ec.europa.eu/justice/data-
                protection/bodies/authorities/index_en.htm.</p>

            <p>If you are located in Switzerland, the contact details for the data protection authorities are available
                here:&#160;https://www.edoeb.admin.ch/edoeb/en/home.html&#160;.</p>

            <p>Withdrawing your consent:&#160;If we are relying on your consent to process your personal
                information,&#160;which may be express and/or implied consent depending on the applicable law,&#160;you
                have the right to withdraw your consent at any time. You can withdraw your consent at any time by
                contacting us by using the contact details provided in the section&#160;&quot;HOW CAN YOU CONTACT US
                ABOUT THIS NOTICE?&quot;&#160;below.</p>

            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal
                nor,&#160;when applicable law allows,&#160;will it affect the processing of your personal information
                conducted in reliance on lawful processing grounds other than consent.</p>

            <p>Cookies and similar technologies:&#160;Most Web browsers are set to accept cookies by default. If you
                prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you
                choose to remove cookies or reject cookies, this could affect certain features or services of our
                Services. To opt out of interest-based advertising by advertisers on our Services
                visit&#160;http://www.aboutads.info/choices/&#160;.</p>

            <p>If you have questions or comments about your privacy rights, you may email us
                at&#160;stephen.macintosh@hydrogenpowerpartners.com.</p>

            <p className={"font-essentialSansMedium"}>10. CONTROLS FOR DO-NOT-TRACK FEATURES</p>

            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data
                about your online browsing activities monitored and collected. At this stage no uniform technology
                standard for&#160;recognizing&#160;and implementing DNT signals has been&#160;finalized. As such, we do
                not currently respond to DNT browser signals or any other mechanism that automatically communicates your
                choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the
                future, we will inform you about that practice in a revised version of this privacy notice.</p>

            <p className={"font-essentialSansMedium"}>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>

            <p>In Short:&#160;Yes, if you are a resident of California, you are granted specific rights regarding access
                to your personal information.</p>

            <p>California Civil Code Section 1798.83, also known as the&#160;&quot;Shine The Light&quot;&#160;law,
                permits our users who are California residents to request and obtain from us, once a year and free of
                charge, information about categories of personal information (if any) we disclosed to third parties for
                direct marketing purposes and the names and addresses of all third parties with which we shared personal
                information in the immediately preceding calendar year. If you are a California resident and would like
                to make such a request, please submit your request in writing to us using the contact information
                provided below.</p>

            <p>If you are under 18 years of age, reside in California, and have a registered account with Services, you
                have the right to request removal of unwanted data that you publicly post on the Services. To request
                removal of such data, please contact us using the contact information provided below and include the
                email address associated with your account and a statement that you reside in California. We will make
                sure the data is not publicly displayed on the Services, but please be aware that the data may not be
                completely or comprehensively removed from all our systems (e.g.,&#160;backups, etc.).</p>

            <p>CCPA Privacy Notice</p>

            <p>The California Code of Regulations defines a&#160;&quot;resident&quot;&#160;as:</p>

            <p>(1) every individual who is in the State of California for other than a temporary or transitory purpose
                and (2) every individual who is domiciled in the State of California who is outside the State of
                California for a temporary or transitory purpose</p>

            <p>All other individuals are defined as&#160;&quot;non-residents.&quot;</p>

            <p>If this definition of&#160;&quot;resident&quot;&#160;applies to you, we must adhere to certain rights and
                obligations regarding your personal information.</p>

            <p>What categories of personal information do we collect?</p>

            <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
            <p>Category Examples Collected</p>
            <div>
                <table className={"table-auto"}>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            A. Identifiers Contact details, such as real name, alias, postal address,
                            telephone or mobile contact number, unique personal identifier, online identifier, Internet
                            Protocol
                            address, email address, and account name
                        </td>
                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            B. Personal information categories listed in the California Customer Records statute Name,
                            contact
                            information, education, employment, employment history, and financial information
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            C. Protected classification characteristics under California or federal law Gender and date
                            of
                            birth
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            D. Commercial information Transaction information, purchase history, financial details, and
                            payment
                            information
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            E. Biometric information Fingerprints and voiceprints
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            F. Internet or other similar network activity Browsing history, search history,
                            online&#160;behavior,
                            interest data, and interactions with our and other websites, applications, systems, and
                            advertisements
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            G. Geolocation data Device location
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            H. Audio, electronic, visual, thermal, olfactory, or similar information Images and audio,
                            video
                            or
                            call recordings created in connection with our business activities
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            I. Professional or employment-related information Business contact details in order to
                            provide
                            you
                            our Services at a business level or job title, work history, and professional qualifications
                            if
                            you
                            apply for a job with us
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            J. Education Information Student records and directory information
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                    <tr>
                        <td className={"border border-black border-1 p-3"}>
                            K. Inferences drawn from other personal information Inferences drawn from any of the
                            collected
                            personal information listed above to create a profile or summary about, for example, an
                            individual&acirc;&euro;&trade;s preferences and characteristics
                        </td>

                        <td className={"border border-black border-1 p-3"}>
                            NO
                        </td>
                    </tr>
                </table>
            </div>
            <p>We may also collect other personal information outside of these categories through instances where you
                interact with us in person, online, or by phone or mail in the context of:  &bull; Receiving help
                through our customer support channels;</p>

            <p> &bull; Participation in customer surveys or contests; and</p>

            <p> &bull; Facilitation in the delivery of our Services and to respond to your inquiries. How do we use and
                share your personal information?</p>

            <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>

            <p>You may contact us&#160;by email at&#160;stephen.macintosh@hydrogenpowerpartners.com,&#160;or by
                referring to the contact details at the bottom of this document.</p>

            <p>If you are using an&#160;authorized&#160;agent to exercise your right to opt out we may deny a request if
                the&#160;authorized&#160;agent does not submit proof that they have been validly&#160;authorized&#160;to
                act on your behalf.</p>

            <p>Will your information be shared with anyone else?</p>

            <p>We may disclose your personal information with our service providers pursuant to a written contract
                between us and each service provider. Each service provider is a for-profit entity that processes the
                information on our behalf.</p>

            <p>We may use your personal information for our own business purposes, such as for undertaking internal
                research for technological development and demonstration. This is not considered to
                be&#160;&quot;selling&quot;&#160;of your personal information.</p>

            <p>HPP, LLC&#160;has not disclosed or sold any personal information to third parties for a business or
                commercial purpose in the preceding twelve (12) months.&#160;HPP, LLC&#160;will not sell personal
                information in the future belonging to website visitors, users, and other consumers.</p>

            <p>Your rights with respect to your personal data</p>

            <p>Right to request deletion of the data &acirc;&euro;&rdquo; Request to delete</p>

            <p>You can ask for the deletion of your personal information. If you ask us to delete your personal
                information, we will respect your request and delete your personal information, subject to certain
                exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her
                right to free speech, our compliance requirements resulting from a legal obligation, or any processing
                that may be required to protect against illegal activities.</p>

            <p>Right to be informed &acirc;&euro;&rdquo; Request to know</p>

            <p>Depending on the circumstances, you have a right to know:  &bull; whether we collect and use your
                personal information;</p>

            <p> &bull; the categories of personal information that we collect;</p>

            <p> &bull; the purposes for which the collected personal information is used;</p>

            <p> &bull; whether we sell your personal information to third parties;</p>

            <p> &bull; the categories of personal information that we sold or disclosed for a business purpose;</p>

            <p> &bull; the categories of third parties to whom the personal information was sold or disclosed for a
                business purpose; and</p>

            <p> &bull; the business or commercial purpose for collecting or selling personal information. In accordance
                with applicable law, we are not obligated to provide or delete consumer information that is
                de-identified in response to a consumer request or to re-identify individual data to verify a consumer
                request.</p>

            <p>Right to Non-Discrimination for the Exercise of a Consumer&acirc;&euro;&trade;s Privacy Rights</p>

            <p>We will not discriminate against you if you exercise your privacy rights.</p>

            <p>Verification process</p>

            <p>Upon receiving your request, we will need to verify your identity to determine you are the same person
                about whom we have the information in our system. These verification efforts require us to ask you to
                provide information so that we can match it with information you have previously provided us. For
                instance, depending on the type of request you submit, we may ask you to provide certain information so
                that we can match the information you provide with the information we already have on file, or we may
                contact you through a communication method (e.g.,&#160;phone or email) that you have previously provided
                to us. We may also use other verification methods as the circumstances dictate.</p>

            <p>We will only use personal information provided in your request to verify your identity or authority to
                make the request. To the extent possible, we will avoid requesting additional information from you for
                the purposes of verification. However, if we cannot verify your identity from the information already
                maintained by us, we may request that you provide additional information for the purposes of verifying
                your identity and for security or fraud-prevention purposes. We will delete such additionally provided
                information as soon as we finish verifying you.</p>

            <p>Other privacy rights</p>

            <p> &bull; You may object to the processing of your personal information.</p>

            <p> &bull; You may request correction of your personal data if it is incorrect or no longer relevant, or ask
                to restrict the processing of the information.</p>

            <p> &bull; You can designate an&#160;authorized&#160;agent to make a request under the CCPA on your behalf.
                We may deny a request from an&#160;authorized&#160;agent that does not submit proof that they have been
                validly&#160;authorized&#160;to act on your behalf in accordance with the CCPA.</p>

            <p> &bull; You may request to opt out from future selling of your personal information to third parties.
                Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no
                later than fifteen (15) days from the date of the request submission. To exercise these rights, you can
                contact us&#160;by email at&#160;stephen.macintosh@hydrogenpowerpartners.com,&#160;or by referring to
                the contact details at the bottom of this document. If you have a complaint about how we handle your
                data, we would like to hear from you.</p>

            <p className={"font-essentialSansMedium"}>12. DO WE MAKE UPDATES TO THIS NOTICE?</p>

            <p>In Short:&#160;Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

            <p>We may update this privacy notice from time to time. The updated version will be indicated by an
                updated&#160;&quot;Revised&quot;&#160;date and the updated version will be effective as soon as it is
                accessible. If we make material changes to this privacy notice, we may notify you either by prominently
                posting a notice of such changes or by directly sending you a notification. We encourage you to review
                this privacy notice frequently to be informed of how we are protecting your information.</p>

            <p className={"font-essentialSansMedium"}>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>

            <p>If you have questions or comments about this notice, you may&#160;contact our Data Protection Officer
                (DPO),&#160;Stephen MacIntosh,&#160;by email
                at&#160;stephen.macintosh@hydrogenpowerpartners.com,&#160;or by post to:</p>

            <p>HPP, LLC Stephen MacIntosh 319 East Carrillo Street Santa Barbara,&#160;CA&#160;93101 United States</p>

            <p className={"font-essentialSansMedium"}>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?</p>

            <p>Based on the applicable laws of your country, you may have the right to request access to the personal
                information we collect from you, change that information, or delete it.&#160;To request to review,
                update, or delete your personal information,
                please&#160;visit:&#160;http://www.hydrogenpowerpartners.com/contact&#160;.</p>
        </div>
    )
}
